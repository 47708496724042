.our_menu-burgers {
  position: relative;
  display: block;
  padding-top: 30px;
  @include clearfix();
  &.bg-gray, .bg-gray {
    background-color: #e4e5e9;
  }
  &.bg-black {
    background: black !important;
    color: white;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      position: relative;
      text-align: left;
    }
  }
  h1, h2.imitate-h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 42px;
  }
  .icons {
    width: 20px;
    position: absolute;
    left: -25px;
    top: 0;
  }

  p,
  span {
    font-size: 12px;
  }

  .cals,
  .price {
    float: right;
  }

  .price {
    margin-right: 5px;
  }

  li strong {
    font-size: 14px;
  }
  .full-container {
    width: 100%;
    display: table;
    padding: 0 30px;
    &.reset-padding {
      padding: 0 15px;
    }
    &.reset-padding0 {
      padding: 0;
    }
    &.yellow-bg {
      color: $purple-bg;
      font-weight: bold;
      border-top: 2px solid $purple-bg;
      border-bottom: 2px solid $purple-bg;
      background: #ffe9ca;
      text-align: center;
      padding: 10px 15px;
    }

    &.bg-black {
      background: black !important;
      color: white;
    }
    img.img-responsive {
      position: absolute;
      max-width: 80%;
      margin-top: -15px;
      right: -30px;
      top: 0;
    }
    h3 {
      margin-top: 0;
      padding-top: 0;
    }
    h4 {
      margin-bottom: 0;
    }
  }
  .left-side, .right-side {
    position: relative;
    float: left;
    width: 50%;
    padding: 30px;
    @include clearfix();
  }
  .small-text-list {
    font-size: 12px;
  }
  .column-one-four {
    padding: 0 0 0 10px;
    width: 25%;
    float: left;
    @include clearfix();
    .price {
      float: none;
      margin-left: 5px;
    }
  }
  .menu-burgers-img-left-fixed {
    left: -30px;
    max-width: 100% !important;
  }
  .menu-burgers-img-right-fixed {
    max-width: 100%;
    margin-top: -60px;
    right: -30px;
  }
  .additional-text-mtop60 {
    margin-top: 60px;
  }
  @media screen and (max-width: 720px) {
    .left-side, .right-side {
      width: 100% !important;
      padding: 15px;
    }
    .additional-text-mtop60 {
      margin-top: 0;
    }
    .full-container {

      img.img-responsive {
        max-width: 100%;
        position: relative;
        margin: 0;
      }
    }
    .menu-burgers-img-left-fixed {
      left: -45px !important;
    }
    .menu-burgers-img-right-fixed {
      right: -45px !important;
    }
    .column-one-four {
      width: 100%;
    }
  }
}