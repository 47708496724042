
.our_menu-beverages {
  position: relative;
  display: block;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
  &.bg-gray {
    background-color: #e4e5e9;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  li {
    text-align: left;
    position: relative;
  }

  p,
  span {
    font-size: 12px;
    line-height: 16px;
  }

  li {
    line-height: 20px;
    display: block;
    @include clearfix();
  }

  .icons {
    width: 20px;
    position: absolute;
    left: -25px;
    top: 0;
  }

  .cals,
  .price {
    float: right;
  }

  .price {
    margin-right: 5px;
  }

  li strong {
    font-size: 14px;
  }

  li h3 {
    padding: 0;
    margin: 0;
  }

  h3 strong {
    font-size: 24px;
    text-align: left;
    margin: 0 auto;
    vertical-align: middle;
  }

  .header_on_top {
    width: 100%;

  }

  .margin_top {
    top: 50px;
  }

  .header_background {
    background: black;
    width: 100%;
    height: 100px;
    position: relative;
    display: table;
  }

  .header_background h1 strong {
    color: #ffe9ca;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  .header_background h2 {
    vertical-align: middle;
  }

  .header_background h1 {
    vertical-align: middle;
    margin-left: 30px;
  }

  h2 strong {
    color: #ffe9ca;
    margin: 0 30px;
    font-size: 32px;
    position: relative;
    z-index: 10;
  }

  .header_background:first-child {
    top: 60px;
  }

  .left-side,
  .right-side {
    float: left;
    position: relative;
    width: 50%;
    padding: 30px;
    display: block;

  }

  .left-side:after {
    content: '';
    display: block;
    clear: both;
  }

  .right-side:after {
    content: '';
    display: block;
    clear: both;
  }

  .middle_content {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 0 30px;
  }

  .purple {
    color: #3C1C42;
    font-size: 11px;
  }

  .image_responsive_none {
    display: table;
    margin: auto;
    max-width: 100%;
  }

  .img_right {
    float: right;
    margin: -90px -30px 30px auto;
    position: relative;
    max-width: 350px;
  }

  .small_strong_text {
    font-size: 12px;
  }

  .middle_text {
    text-align: center;
    font-size: 11px;

  }

  .image-shape {
    max-width: 150px;
    display: block;
    float: right;
    margin: 35px 0 35px 10px;
  }

  .image-top {
    position: absolute;
    top: -10px;
    right: 0;
  }

  .image-middle {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 720px) {
    .left-side,
    .right-side {
      width: 100% !important;
    }
    .left-side {
      padding-bottom: 0;
    }
    .right-side {
      padding-top: 0;
    }
    .img_right {
      max-width: 100%;
      float: none;
      margin: 0 auto;
    }
    .header_background {
      display: inline-block;
      &:first-child {
        top: 30px !important;
      }
    }

    .image-top {
      width: 100px;
    }

    .header_on_top {
      position: relative;
    }

    h1 {
      margin: 30px;
    }

    h2 strong {
      float: left;
      margin-bottom: 10px;
    }

    .left-side .image_responsive_none {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 360px) {
    .right-side {
      padding-top: 0;
    }
  }
}