.wines {
  height: 900px;
  display: table;
  table-layout: fixed;
  margin-bottom: 15px
}

.wines, .wines .full-width {
  width: 100%;
  position: relative;
  background: #e4e5e9
}

.wines .full-width {
  display: inline-block;
  float: none;
  height: 100%;
  color: #111
}

.wines .menu-name {
  width: 100%;
  position: relative;
  color: #f5f5f5;
  overflow: hidden;
  background: #111
}

.wines .menu-name h1 {
  font-size: 38px;
  text-align: left;
  font-family: FranklinGothicBookBold, sans-serif;
  color: #f5f5f5;
  font-weight: 700;
  padding: 15px 20px;
  margin: 0
}

.wines .menu-header {
  border: 1px solid #111;
  border-radius: 15px;
  position: relative;
  padding: 10px 20px;
  margin: 10px 20px
}

.wines .menu-header h2 {
  font-size: 32px;
  font-weight: 700
}

.wines .menu-header h2, .wines .menu-header h3 {
  color: #111;
  font-family: FranklinGothicBookBold, sans-serif;
  font-style: italic;
  padding: 0;
  margin: 0
}

.wines .menu-header h3 {
  font-size: 28px
}

.wines .menu-header .wine-image {
  position: absolute;
  right: 20px;
  top: -90px
}

.wines .menu-header .wine-image img {
  max-width: 270px;
  border: 2px solid #bf8933;
  border-radius: 5px
}

.wines .menu-header table {
  width: 100%;
  padding: 0 20px
}

.wines .menu-header table tbody td:nth-child(2), .wines .menu-header table tbody td:nth-child(3), .wines .menu-header table thead tr {
  text-align: right
}

.wines .menu-content {
  width: 100%;
  position: relative;
  display: block
}

.wines .menu-content .left-width-10 {
  width: 8%;
  float: left;
  display: inline;
  position: relative
}

.wines .menu-content .left-width-10 .rotated-name {
  margin-top: 300px;
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  color: #111;
  font-family: FranklinGothicBookBold, sans-serif;
  font-style: italic;
  font-size: 32px;
  text-transform: uppercase
}

.wines .menu-content .left-width-10 .rotated-name:before {
  content: " ";
  position: absolute;
  left: 150px;
  top: 20px;
  width: 100%;
  height: 1px;
  background: #000
}

.wines .menu-content .left-width-10 .rotated-name:after {
  content: " ";
  position: absolute;
  right: 100px;
  top: 20px;
  width: 100%;
  height: 1px;
  background: #000
}

.wines .menu-content .right-width-90 {
  width: 92%;
  float: left;
  display: inline;
  position: relative;
  padding: 20px
}

.wines .menu-content table {
  width: 100%
}

.wines .menu-content table tbody td:nth-child(2), .wines .menu-content table tbody td:nth-child(3), .wines .menu-content table tbody td:nth-child(4), .wines .menu-content table thead td:nth-child(2), .wines .menu-content table thead td:nth-child(3), .wines .menu-content table thead td:nth-child(4) {
  text-align: center
}

.wines .menu-content table thead td:nth-child(2), .wines .menu-content table thead td:nth-child(3), .wines .menu-content table thead td:nth-child(4) {
  font-weight: 700
}

.wines .menu-content .purple-colored {
  text-align: right;
  color: #7d3a8a;
  font-style: italic
}