.our_menu-desserts {
  position: relative;
  display: block;
  padding-top: 30px;
  @include clearfix();
  &.bg-gray, .bg-gray {
    background-color: #e4e5e9;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      position: relative;
      text-align: left;
    }
  }

  .icons {
    width: 20px;
    position: absolute;
    left: -25px;
    top: 0;
  }

  p,
  span {
    font-size: 12px;
  }

  .cals,
  .price {
    float: right;
  }

  .price {
    margin-right: 5px;
  }

  li strong {
    font-size: 14px;
  }

  .header_background {
    background: black;
    width: 100%;
    height: 90px;
    padding: 5px;
  }
  h1 {
    margin-top: 15px;
  }
  .header_background h1 strong {
    color: #ffe9ca;
    font-size: 50px;
    padding: 0 45px;
  }

  .left-side,
  .right-side {
    float: left;
    width: 50%;
    padding: 30px;
    display: block;
  }

  .left-side:after {
    content: '';
    display: block;
    clear: both;
  }

  .right-side:after {
    content: '';
    display: block;
    clear: both;
  }

  .middle_content {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 0 30px;
  }

  .purple {
    color: #3C1C42;
    font-size: 11px;
  }

  .image_responsive_none {
    display: table;
    margin: auto;
    max-width: 100%;
  }

  .small_strong_text {
    font-size: 12px;
  }

  .middle_text {
    text-align: center;
    font-size: 11px;

  }

  .image-shape {
    max-width: 150px;
    display: block;
    float: right;
    margin: 35px 0 35px 10px;
  }

  .image-top {
    position: absolute;
    top: -10px;
    right: 0;
  }

  .image-middle {
    margin-bottom: 20px;

  }

  @media screen and (max-width: 720px) {
    .left-side,
    .right-side {
      width: 100% !important;
    }

    .image-shape {
      max-width: 120px;
    }

    .image-top {
      max-width: 300px;
      position: absolute;
      top: 70px;
      right: 0;
    }

  }

  @media screen and (max-width: 360px) {

    .image-top {
      max-width: 200px;
      position: absolute;
      top: 90px;
      right: 0;
    }

    .image-shape {
      width: 100%;
      margin: 10px 30px;
    }

    .header_background h1 strong {
      font-size: 40px;
      padding: 20px;
    }

  }
}

