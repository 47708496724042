
.our_menu-plan-your-party {
  margin-bottom: 30px;
  &.bg-black {
    background: black !important;
    color: white;
    small {
      color: white;
    }
  }
  &.bg-gray {
    background: #e4e5e9 !important;
  }
  @include clearfix();
  .left-side, .right-side {
    float: left;
    width: 50%;
    padding: 30px;
    position: relative;
    @include clearfix();
  }
  .sub-title {
    font-weight: bold;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    li {
      position: relative;
      display: block;
      text-align: right;
      @include clearfix();
      span {
        &:first-child {
          float: left;
          text-align: left;
        }
        display: inline-block;
      }
      p {
        text-align: left;
        span {
          display: inline-block;
          &:first-child {
            float: left;
          }
          &.cals {
            float: right;
          }
        }
      }
      img {
        width: 20px;
        position: absolute;
        left: -25px;
        top: 0;
        &.none-position {
          position: relative;
          top: auto;
          left: auto;
        }
      }
    }
  }
  .purple-ribbon {
    background: $purple-bg;
    padding: 5px 10px;
    position: absolute;
    left: 0;
    right: 0;

    color: white;
  }
}