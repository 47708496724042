
.our_menu-kids {
  position: relative;
  .title {
    position: absolute;
    margin-top: -90px;
    background: $purple-bg;
    width: 100%;
    color: white;
    padding: 15px 20px;
    h1 {
      padding: 0;
      margin: 0;
      font-weight: bold;
      small {
        float: right;
        color: white;
      }
    }
    p {
      margin: 0;
      font-size: 12px;
    }
  }

}