@charset "UTF-8";
/* Same height */
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.container {
  padding-right: 15px;
  padding-left: 15px; }

.row .row {
  margin-top: 0;
  margin-bottom: 15px; }

.row .locations-list .row {
  margin-bottom: 0; }

/* Fonts */
@font-face {
  font-family: 'FranklinGothicBookBold';
  src: url("/fonts/FRABK.woff"); }

/* Defaults */
.nav-top .top-left-nav, .nav-top .top-right-nav, nav ul, .get-social-list, .locations-list .opening-soon-list, .widget-social-list, .widget-services-list ul, .location-socials .social-widgets, .footer-list, .footer-contact-us, .top-left-list {
  margin: 0;
  padding: 0; }

.footer-list li, .footer-contact-us li {
  margin: 0;
  padding: 0;
  list-style: none; }

.nav-top .top-left-nav li, .nav-top .top-right-nav li, nav li, .get-social-list li, .widget-social-list li, .sub-navigation li, .our-menu-seven-days li, .top-left-list li {
  float: left;
  display: inline;
  list-style: none; }

body {
  background: url("../../images/bg.png");
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  line-height: 1.4;
  overflow-y: scroll;
  font-size: 14px; }

a, img {
  -webkit-transition: all .3s ease-in-out;
  -khtml-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  text-decoration: none; }

a:hover {
  text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 10px; }

h1 {
  font-size: 30px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 20px; }

p {
  margin: 0 0 10px 0;
  line-height: 1.5; }

address {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }

form fieldset {
  border: 1px solid #dddddd;
  padding: 0 15px 15px 15px;
  margin: 10px 0; }

form.feedback-form fieldset {
  border: 1px solid #2b2b2b;
  padding: 0 25px 25px 25px;
  margin: 10px 0; }

form.contact-form fieldset {
  border: 1px solid #e7cda4;
  padding: 0 25px 25px 25px;
  margin: 10px 0; }

form.contact-form:hover fieldset {
  border: 1px solid #d1a154; }

form legend {
  background: transparent;
  padding: 0 10px;
  border-bottom: none;
  width: auto;
  font-family: "FranklinGothicBookBold", sans-serif;
  font-weight: bold;
  font-size: 16px; }

form label {
  margin-top: 10px; }

form sup {
  color: red; }

.ul-in-ul {
  padding-left: 15px; }
  .ul-in-ul ul {
    padding-left: 15px;
    list-style-type: square; }

.table > thead {
  background: #3c1c42;
  color: #f5f5f5; }

.table > thead > tr > th {
  border: none; }

.table > tbody > tr:nth-child(even) {
  background: #fcf9f5; }

.table > tbody > tr:hover {
  background: #f2e3cc; }

.clear {
  clear: both; }

.bigger-h2 {
  font-size: 32px; }

.uppercase {
  text-transform: uppercase; }

.font-franklin {
  font-family: "FranklinGothicBookBold", sans-serif; }

.margin-bottom0 {
  margin-bottom: 0 !important; }

.margin-top0 {
  margin-top: 0 !important; }

.margin-right0 {
  margin-right: 0 !important; }

.margin-top10 {
  margin-top: 10px !important; }

.margin-top-5 {
  margin-top: -5px !important; }

.margin-top-15 {
  margin-top: -15px; }

.margin-top-80 {
  margin-top: -80px; }

.margin-top-120 {
  margin-top: -120px; }

.margin-top60 {
  margin-top: 60px !important; }

.padding-top0 {
  padding-top: 0 !important; }

.padding-bottom30 {
  padding-bottom: 15px !important; }

.padding-right15 {
  padding-right: 15px !important; }

.padding0 {
  padding: 0 !important; }

.capitalize {
  text-transform: capitalize; }

.brown-text {
  color: #bf8933; }

.bold {
  font-weight: bold; }

.justify {
  text-align: justify; }

.centered {
  float: none;
  margin: 0 auto;
  display: table;
  max-width: 100%; }

.text-center {
  text-align: center; }

.right {
  float: right; }

.yellow-bg {
  background: #fef4e5; }

.purple-text {
  color: #3c1c42 !important; }

.blue-text {
  color: #5675d0 !important; }

.col-lg-4.yellow-bg {
  margin-left: -15px; }

.line {
  width: 100%;
  height: 1px;
  background: #dddddd;
  margin-bottom: 10px; }

.line-dashed {
  height: 1px;
  margin: 20px 0;
  border-top: 1px dashed #dddddd; }

.img-responsive {
  width: 100%; }

.img-symposium {
  position: relative;
  margin-bottom: 15px; }

.img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 1; }

.img-name {
  font-size: 28px;
  font-family: "FranklinGothicBookBold", sans-serif;
  color: #f5f5f5;
  position: absolute;
  left: 0;
  bottom: 45px;
  background: rgba(17, 17, 17, 0.7);
  padding: 5px 10px;
  z-index: 1; }

.img-name.smaller {
  font-size: 18px;
  bottom: 30px; }

.affix, .affix-top {
  position: static; }

#static {
  max-width: 320px;
  position: relative; }

@media screen and (min-width: 1200px) {
  #static.affix-top {
    position: static; }
  #static.affix {
    position: fixed;
    top: 10px;
    width: 100%; } }

.title-h3 {
  font-size: 26px;
  font-family: "FranklinGothicBookBold", sans-serif;
  color: #bf8933;
  font-weight: bold;
  margin-top: 5px; }

/* Basic */
/* Header */
.black-header {
  background: #111;
  padding-bottom: 100px; }

.nav-top {
  border-bottom: 1px dashed #2b2b2b;
  font-family: "FranklinGothicBookBold", sans-serif; }
  .nav-top a {
    color: #919191;
    display: block;
    letter-spacing: 1px;
    text-transform: uppercase; }
    .nav-top a:hover {
      color: #bf8933; }
  .nav-top .top-left-nav li {
    padding: 9px 15px;
    border-right: 1px dashed #2b2b2b; }
    .nav-top .top-left-nav li:hover {
      background: #2b2b2b; }
  .nav-top .top-left-nav li:first-child {
    border-left: 1px dashed #2b2b2b; }
  .nav-top .top-right-nav {
    float: right; }
  .nav-top .top-right-nav li {
    font-weight: bold;
    border-right: 1px dashed #2b2b2b;
    border-left: 1px dashed #2b2b2b; }
    .nav-top .top-right-nav li a {
      padding: 7px 15px;
      font-size: 16px; }

.padding-logo-nav {
  padding: 20px 0; }

header .logo {
  display: inline;
  float: left; }
  header .logo img {
    display: block;
    max-width: 100%;
    height: auto; }

nav {
  float: right;
  margin-top: 30px;
  position: relative; }
  nav li {
    margin-right: 12px;
    position: relative; }
  nav li ul {
    display: none;
    position: relative; }
  nav li:hover ul {
    display: block;
    position: absolute;
    background: #f5f5f5;
    top: 35px;
    margin: 0;
    list-style: none;
    -moz-column-count: 2;
    -moz-column-gap: 0;
    -webkit-column-count: 2;
    -webkit-column-gap: 0;
    column-count: 2;
    column-gap: 0;
    z-index: 99999;
    padding: 0;
    border-top: 3px solid #bf8933; }
    nav li:hover ul li {
      width: 100%;
      display: inline-block;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid-column; }
      nav li:hover ul li a {
        font-size: 16px;
        padding: 5px 10px;
        color: #3c1c42;
        font-weight: bold;
        font-family: "FranklinGothicBookBold", sans-serif;
        width: 100%;
        display: block; }
      nav li:hover ul li a:hover, nav li:hover ul li a.active {
        border: 0;
        background: #3c1c42;
        color: #f5f5f5; }
      nav li:hover ul li li:hover {
        border: 0; }
  nav li:last-child {
    margin-right: 0; }
  nav a {
    font-family: "FranklinGothicBookBold", sans-serif;
    color: #ebe7e5;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px; }
  nav a:hover, nav a.active {
    color: #bf8933;
    padding-bottom: 10px;
    border-bottom: 3px solid #bf8933; }

/* Page */
/* Default page style */
section .header-image {
  margin-top: -80px;
  position: relative;
  width: 100%;
  background: #fff;
  padding: 40px 40px 0 40px; }

.header-image-with-text {
  width: 50%;
  height: 5%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center; }

.header-image-with-text h1 {
  color: #f5f5f5;
  font-family: "FranklinGothicBookBold", sans-serif;
  /*border-bottom: 1px solid $white-link;*/
  display: inline;
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  background: rgba(0, 0, 0, 0.3);
  padding: 8px 20px; }

.header-image img {
  width: 100%;
  max-height: 380px; }

/** Home **/
section .slider-bg {
  background: #fff;
  padding: 40px;
  margin-top: -80px; }

section .gray-page {
  background: #f1f1f1;
  padding: 0; }

.home-headings {
  margin: 0 0 15px 0;
  padding: 0;
  text-align: center;
  font-weight: bold;
  font-family: "FranklinGothicBookBold", sans-serif;
  font-size: 28px;
  text-transform: uppercase; }

.decor {
  margin: 0 15px;
  position: relative; }
  .decor .decor-bg {
    background: #18181a url("/images/decor-big.jpg") 50% 60% no-repeat;
    background-size: cover;
    height: 300px;
    border-bottom: 3px solid #bf8933; }
  .decor p {
    position: absolute;
    text-align: center;
    top: 20px;
    left: 0;
    right: 0;
    padding: 10px;
    font-size: 18px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    color: #f5f5f5;
    background: rgba(0, 0, 0, 0.6); }

.tp-caption.slider-purple {
  font-family: "FranklinGothicBookBold", sans-serif;
  text-decoration: none;
  color: #f5f5f5;
  white-space: nowrap;
  font-size: 48px;
  background: #3c1c42; }

.tp-caption.slider-purple span {
  padding: 5px 15px !important;
  font-weight: bold; }

.tp-caption.slider-purple a {
  color: #f5f5f5; }

.purple-title-big {
  background: #3c1c42;
  margin: 0;
  padding: 20px;
  text-align: center;
  text-transform: uppercase; }

.purple-title-big h2 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 30px;
  font-family: "FranklinGothicBookBold", sans-serif;
  color: #fff; }

section .page {
  background: #fff;
  padding: 40px; }

.abs-border {
  margin: 0 auto;
  position: relative;
  z-index: 99;
  overflow: hidden; }

.abs-border img {
  width: 100%;
  min-width: 100%; }

.abs-border:before {
  display: block;
  content: '';
  position: absolute;
  top: 8px;
  z-index: 99;
  right: 8px;
  bottom: 8px;
  left: 8px;
  border: 1px solid #e7cda4; }

.abs-border:hover:before {
  display: block;
  content: '';
  position: absolute;
  z-index: 99;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  border: 1px solid #bf8933; }

.abs-border:hover img.abs-border-img {
  -webkit-transform: rotate(5deg) scale(1.1);
  /* Chrome 4+, Op 15+, Saf 3.1, iOS Saf 3.2+ */
  -moz-transform: rotate(5deg) scale(1.1);
  /* Fx 3.5-15 */
  -ms-transform: rotate(5deg) scale(1.1);
  /* IE 9 */
  -o-transform: rotate(5deg) scale(1.1);
  /* Op 10.5-12 */
  transform: rotate(5deg) scale(1.1);
  /* Fx 16+, IE 10+ */
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  -khtml-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

/* MINI ABS BORDER */
.abs-border-mini {
  margin: 0 auto;
  position: relative;
  z-index: 99;
  overflow: hidden; }

.abs-border-mini img {
  width: 100%;
  min-width: 100%; }

.abs-border-mini:before {
  display: block;
  content: '';
  position: absolute;
  top: 5px;
  z-index: 99;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 1px solid #e7cda4; }

.abs-border-mini:hover:before {
  display: block;
  content: '';
  position: absolute;
  z-index: 99;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 1px solid #bf8933; }

.abs-border-mini:hover img.abs-border-img {
  -webkit-transform: rotate(5deg) scale(1.1);
  /* Chrome 4+, Op 15+, Saf 3.1, iOS Saf 3.2+ */
  -moz-transform: rotate(5deg) scale(1.1);
  /* Fx 3.5-15 */
  -ms-transform: rotate(5deg) scale(1.1);
  /* IE 9 */
  -o-transform: rotate(5deg) scale(1.1);
  /* Op 10.5-12 */
  transform: rotate(5deg) scale(1.1);
  /* Fx 16+, IE 10+ */
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  -khtml-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

.get-social {
  position: absolute;
  z-index: 9999;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 30px; }

.get-social-list {
  display: table;
  margin: 0 auto; }

.get-social-list li {
  margin-right: 7px;
  background: transparent; }

.get-social-list li:hover {
  -webkit-box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
  -moz-box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
  box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }

.get-social-list img {
  max-width: 32px; }

/* Locations */
section .page-without-top {
  padding: 0 40px 40px 40px;
  background: #fff; }

.no-margin {
  margin: 0; }

.no-margin-top {
  margin-top: 0; }

.locations-list {
  border: 1px solid #bf8933; }
  .locations-list .two-columns-list {
    padding: 0;
    margin: 0;
    list-style: none;
    -moz-column-count: 2;
    -moz-column-gap: 0;
    -webkit-column-count: 2;
    -webkit-column-gap: 0;
    column-count: 2;
    column-gap: 0; }
  .locations-list .three-columns-list {
    padding: 0;
    width: 33.3334%;
    margin: 0;
    list-style: none;
    float: left; }
  .locations-list .two-columns-list li, .locations-list .locations-list .three-columns-list li {
    border-bottom: 1px solid #f5f5f5;
    width: 100%;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    display: table; }
  .locations-list .two-columns-list li a, .locations-list .three-columns-list li a {
    font-size: 20px;
    padding: 5px 25px;
    color: #3c1c42;
    font-weight: bold;
    font-family: "FranklinGothicBookBold", sans-serif;
    width: 100%;
    display: block; }
  .locations-list .two-columns-list li a:hover, .locations-list .three-columns-list li a:hover {
    color: #7d3a8a;
    background: #f7f7f7; }
  .locations-list h3 {
    text-transform: uppercase;
    font-family: "FranklinGothicBookBold", sans-serif;
    font-size: 28px; }

.locations-list .opening-soon-list {
  list-style-type: none; }

.locations-list .opening-soon-list li {
  cursor: default;
  font-size: 20px;
  padding: 5px 25px;
  color: #3c1c42;
  font-weight: bold;
  font-family: "FranklinGothicBookBold", sans-serif;
  border-bottom: 1px solid #f5f5f5; }

.locations-list .opening-soon-list li:hover {
  background: #fcdfb4; }

/* About Us */
.about-us p {
  margin-bottom: 0; }

.about-us .line-dashed {
  height: 1px;
  margin: 10px 0;
  border-top: 1px dashed #dddddd; }

.height365 {
  max-height: 365px; }

.image-in-paragraph {
  display: inline;
  padding: 5px; }
  .image-in-paragraph .abs-border {
    display: block; }
  .image-in-paragraph .text {
    display: block;
    text-align: right;
    font-size: 12px;
    color: #111; }

.image-in-paragraph.right {
  float: right; }

.image-in-paragraph.left {
  float: left; }
  .image-in-paragraph.left .text {
    text-align: left; }

/** Location **/
section .location-slider-bg {
  margin-top: -80px;
  position: relative;
  width: 100%;
  z-index: 1;
  background: #fff;
  padding: 40px 40px 30px 40px; }

.location-banner {
  height: 362px; }
  .location-banner ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: none; }

.location-title {
  background: #3c1c42;
  width: 100%;
  padding: 15px 0;
  margin-bottom: 5px; }
  .location-title h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #f5f5f5;
    font-weight: bold;
    font-family: "FranklinGothicBookBold", sans-serif;
    text-transform: uppercase;
    font-size: 32px; }
  .location-title a {
    color: #f5f5f5; }

.promotions-seven {
  display: none;
  width: 100%;
  background: #3c1c42;
  color: #f5f5f5;
  padding: 10px;
  margin-bottom: 10px; }
  .promotions-seven h2 {
    font-size: 18px;
    font-family: "FranklinGothicBookBold", sans-serif;
    border-right: 1px solid #bf8933;
    margin: 0;
    padding: 0 10px;
    display: inline;
    float: left; }
  .promotions-seven .content-of-day {
    padding-left: 10px;
    overflow: hidden; }

.location-for-you-list {
  border-top: 1px dashed #dcb77c;
  margin-top: 10px;
  padding: 10px 0;
  list-style: none;
  float: left;
  width: 50%; }
  .location-for-you-list li {
    margin-bottom: 5px; }
  .location-for-you-list li:before {
    color: #bf8933;
    content: "~";
    font-size: 18px;
    margin-right: 5px; }

.location-widget {
  z-index: 999;
  margin-top: -80px;
  margin-right: -5px; }
  .location-widget .yellow-bg {
    z-index: 999;
    padding: 15px;
    -webkit-box-shadow: -1px 13px 53px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 13px 53px -12px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 13px 53px -12px rgba(0, 0, 0, 0.75); }

.widget.contact {
  padding: 15px; }
  .widget.contact h3 {
    margin: 0 0 15px 0;
    padding: 0;
    text-align: center; }

.widget {
  width: 100%;
  display: inline-block; }
  .widget time {
    display: block; }
  .widget time span {
    float: right;
    font-weight: bold; }
  .widget .location-map {
    margin-bottom: 10px;
    height: 180px;
    width: 100%; }

.widget-social-list {
  display: table;
  margin: 0 auto; }
  .widget-social-list li {
    margin-right: 10px;
    background: transparent; }
  .widget-social-list li:last-child {
    margin-right: 0; }
  .widget-social-list li:hover {
    -webkit-box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
    -moz-box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
    box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px; }
  .widget-social-list img {
    max-width: 32px; }

.widget-services-list ul {
  text-align: center; }
  .widget-services-list ul li {
    position: relative;
    display: inline-block;
    text-align: center;
    margin: 5px 7px; }

.paymentAccepted {
  text-align: center; }

.location-socials {
  margin: 30px 0;
  padding: 0 15px;
  border: 1px solid #bf8933;
  font-style: italic; }
  .location-socials .locations-reviews-list {
    list-style: none;
    float: left;
    width: 50%;
    border-top: 1px dashed #dcb77c;
    margin-top: 10px;
    padding: 10px 0; }
    .location-socials .locations-reviews-list li:before {
      color: #bf8933;
      content: "★";
      font-size: 18px;
      margin-right: 5px; }
  .location-socials .social-widgets {
    display: table;
    margin: 0 auto; }
    .location-socials .social-widgets li {
      text-align: center;
      display: table;
      margin: 10px auto 0 auto;
      list-style-type: none; }

.location-maps iframe {
  width: 100%;
  height: 365px;
  margin: 0;
  padding: 0; }

.location-maps .location-box-text {
  background: #fef4e5;
  color: #111;
  padding: 7px 10px;
  border: 1px solid #bf8933;
  margin: 0; }

/* Franchise */
.sub-navigation {
  display: inline-block;
  position: relative;
  width: 100%;
  background: #3c1c42; }

.sub-navigation ul {
  padding: 0;
  list-style-type: none;
  margin: 0 auto;
  display: table; }

.sub-navigation li {
  text-align: center;
  border-right: 1px dashed #5d2b66; }

.sub-navigation li:last-child {
  border-right: 1px solid transparent; }

.sub-navigation li.clone-href {
  text-transform: uppercase;
  color: #f5f5f5;
  display: block;
  font-size: 14px;
  border-right: 1px dashed #5d2b66;
  padding: 7px 10px;
  cursor: default; }

.sub-navigation li a {
  text-transform: uppercase;
  color: #f5f5f5;
  display: block;
  font-size: 14px;
  padding: 7px 10px; }

.sub-navigation li a:hover, .sub-navigation li a.active {
  display: block;
  background: #5d2b66; }

.autoplay .slick-list:before {
  background: transparent !important; }

.autoplay .slick-list:after {
  background: transparent !important; }

.autoplay .cfa-images img {
  padding: 15px; }

.franchise-list ul {
  padding-left: 20px; }

.franchise-heading {
  font-size: 18px;
  font-weight: bold; }

a .franchise-heading {
  color: #111; }

a .franchise-heading:hover {
  color: #bf8933; }

.panel-default > .panel-heading {
  border-bottom: 1px solid #f2e3cc;
  background: #fcf9f5; }

.panel-default > .panel-heading:hover {
  background: #f2e3cc; }

.panel-title {
  margin: 0;
  padding: 10px 15px; }

.panel-title a {
  color: #bf8933; }

.panel-body {
  padding: 15px 30px; }

.current-opportunities .header {
  background: #341905;
  color: #f5f5f5;
  display: table;
  padding: 30px;
  width: 100%;
  height: 100%;
  margin: 0; }
  .current-opportunities .header h2 {
    margin: 0;
    padding: 0;
    float: left;
    text-transform: uppercase;
    font-family: "FranklinGothicBookBold", sans-serif;
    font-size: 53px;
    font-weight: bold;
    max-width: 50%;
    line-height: 53px; }
  .current-opportunities .header .logo {
    float: right;
    max-width: 100%; }

.current-opportunities .content {
  width: 100%;
  margin: 0;
  padding: 30px 30px 15px 30px;
  background: #e4e5e9;
  display: table; }
  .current-opportunities .content h2.location-name {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 43px;
    font-size: 38px;
    color: #341905; }
  .current-opportunities .content ul {
    width: 50%;
    max-width: 50%;
    float: left;
    padding: 30px 45px 0 45px;
    font-weight: bold;
    font-size: 28px;
    font-family: "FranklinGothicBookBold", sans-serif; }

.current-opportunities .images {
  width: 100%; }
  .current-opportunities .images .half-width {
    width: 50%;
    float: left; }
  .current-opportunities .images .border-6303-l {
    border-right: 6px solid #f5f5f5;
    border-bottom: 6px solid #f5f5f5;
    border-top: 9px solid #f5f5f5; }
  .current-opportunities .images .border-6303-r {
    border-left: 6px solid #f5f5f5;
    border-bottom: 6px solid #f5f5f5;
    border-top: 9px solid #f5f5f5; }
  .current-opportunities .images .border-3000 {
    border-top: 6px solid #f5f5f5; }

.current-opportunities .full-width {
  position: relative; }
  .current-opportunities .full-width .border-box {
    padding: 15px;
    width: 45%;
    float: right;
    position: absolute;
    right: 30px;
    bottom: -125px;
    background: #fef4e5;
    border-top: 3px solid #341905;
    border-left: 3px solid #341905;
    border-right: 3px solid #341905;
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px; }
    .current-opportunities .full-width .border-box ul {
      font-size: 20px;
      font-weight: bold;
      padding: 0;
      font-family: "FranklinGothicBookBold", sans-serif; }
      .current-opportunities .full-width .border-box ul li {
        width: 100%;
        display: block; }
      .current-opportunities .full-width .border-box ul li:before {
        content: '•';
        margin-right: 10px; }

.current-opportunities .awards-texts {
  display: table;
  position: relative; }
  .current-opportunities .awards-texts ul {
    max-width: 60%;
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .current-opportunities .awards-texts ul li {
      float: left;
      display: inline;
      width: 25%;
      padding: 5px; }
      .current-opportunities .awards-texts ul li img {
        width: 100%; }

.current-opportunities .informations {
  padding: 15px;
  color: #f5f5f5;
  background: #341905;
  display: table;
  width: 100%;
  text-align: right;
  font-size: 24px; }

/* Widget */
.widget {
  padding: 15px 0; }

.widget .header-h2 {
  text-align: center;
  display: table;
  margin: 0 auto; }

.widget h2 {
  padding: 0 0 15px;
  margin: 0 0 15px 0;
  float: left;
  display: inline-block;
  font-family: 'FranklinGothicBookBold', sans-serif;
  font-weight: bold;
  border-bottom: 1px solid #dcb77c; }

.border-bottom {
  width: 50%;
  height: 1px;
  background: #dcb77c;
  margin: 0 auto 10px auto; }

.widget .menu-categories {
  padding: 0;
  margin-top: 15px; }

.widget .menu-categories li {
  list-style-type: none;
  margin-bottom: 5px; }

.widget .menu-categories ul {
  margin-left: 15px;
  padding: 0; }

.widget .menu-categories ul li {
  margin-bottom: 0; }

.widget .menu-categories ul a {
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  padding: 4px 15px;
  font-weight: normal;
  display: block;
  color: #111; }

.widget .menu-categories a {
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  padding: 8px 15px;
  display: block;
  font-weight: bold;
  color: #111; }

.widget .menu-categories a:hover, .widget .menu-categories a.active {
  background: #3c1c42;
  color: #f5f5f5; }

/* Gallery */
.gallery-content h1 {
  margin: 0;
  padding: 0;
  font-family: "FranklinGothicBookBold", sans-serif;
  font-size: 28px; }

.gallery {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative; }

.gallery:after {
  display: block;
  height: 0;
  content: '';
  clear: both; }

.gallery figure a {
  display: block;
  margin: 7px;
  border: 1px solid #dddddd; }

.gallery figure a:hover {
  border: 1px solid #3c1c42; }

.gallery figure {
  float: left;
  /*clear: left;*/
  width: 33.3334%;
  opacity: .9;
  position: relative;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent; }

.gallery figure:hover {
  opacity: 1; }

.gallery figure img {
  display: block;
  width: 100%; }

.gallery figure:before {
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0, transparent 80%);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity; }

.gallery figure:active, .gallery figure:focus, .gallery figure:hover {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
  -webkit-animation-name: hover;
  animation-name: hover;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate; }

.gallery figure:active:before, .gallery figure:focus:before, .gallery figure:hover:before {
  opacity: .4;
  -webkit-transform: translateY(6px);
  transform: translateY(6px);
  -webkit-animation-name: hover-shadow;
  animation-name: hover-shadow;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate; }

.gallery .gallery-description {
  display: none; }

/* Forms */
.slider-range {
  margin-top: 25px; }

/* Footer */
footer a {
  color: #919191; }

footer a:hover {
  color: #dddddd; }

footer a.yellow {
  color: #bf8933; }

footer a.yellow:hover {
  color: #e7cda4; }

.black-footer {
  background: #111;
  color: #919191;
  font-size: 13px;
  padding: 20px 0; }

.footer-logo img {
  max-width: 190px;
  width: 190px;
  margin-bottom: 10px; }

.footer-socials {
  margin-top: 20px; }
  .footer-socials .col-xs-2, .footer-socials .col-xs-10 {
    padding: 0;
    margin: 0; }
  .footer-socials .small_icon {
    margin: 0 auto;
    display: table;
    text-align: center;
    line-height: 32px; }
    .footer-socials .small_icon img {
      float: left;
      display: inline;
      max-height: 32px; }
    .footer-socials .small_icon span {
      margin-left: 10px; }
  .footer-socials .instagram {
    float: left;
    display: inline;
    margin-right: 10px;
    margin-bottom: 10px; }
  .footer-socials .instagram img {
    max-height: 46px; }

small.smaller {
  font-size: 9px; }

.footer-title {
  font-weight: bold;
  color: #f5f5f5;
  font-size: 14px;
  margin-bottom: 20px; }

.footer-title small {
  color: #919191;
  font-weight: normal; }

.footer-list li {
  margin-bottom: 15px;
  font-size: 14px; }

.footer-contact-us li {
  margin-bottom: 15px;
  font-size: 14px; }

.footer-contact-us li:last-child, .footer-list li:last-child {
  margin-bottom: 0; }

/****************** OUR MENU ******************/
.normalized-h1 {
  margin: 0;
  padding: 0;
  font-family: "FranklinGothicBookBold", sans-serif;
  font-size: 28px; }

/* 7 Days */
.our-menu-seven-days {
  display: inline-block;
  position: relative;
  width: 100%;
  background: #3c1c42; }
  .our-menu-seven-days ul {
    padding: 0;
    list-style-type: none;
    margin: 0 auto;
    display: table; }
  .our-menu-seven-days li {
    text-align: center;
    border-right: 1px dashed #5d2b66; }
  .our-menu-seven-days li:last-child {
    border-right: 1px solid transparent; }
  .our-menu-seven-days li a {
    text-transform: uppercase;
    color: #f5f5f5;
    font-family: "FranklinGothicBookBold", sans-serif;
    display: block;
    font-size: 16px;
    padding: 7px 10px;
    text-decoration: none; }
  .our-menu-seven-days li a:hover, .our-menu-seven-days li a.active, .our-menu-seven-days li a:focus, .our-menu-seven-days li a:active {
    display: block;
    text-decoration: none;
    background: #5d2b66; }

.our-menu-special {
  height: 900px;
  width: 100%;
  display: table;
  table-layout: fixed;
  /* left */
  /* right */ }
  .our-menu-special .day-name {
    height: 900px;
    display: table-cell;
    width: 25%;
    position: relative;
    background: #341905; }
    .our-menu-special .day-name h2 {
      margin-left: 20px;
      letter-spacing: 7px;
      color: #f5f5f5;
      font-family: "FranklinGothicBookBold", sans-serif;
      font-size: 132px;
      text-transform: uppercase;
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
  .our-menu-special .day-content {
    width: 75%;
    height: 900px;
    display: table-cell;
    padding: 0;
    background: #fcf9f5; }
    .our-menu-special .day-content .logo {
      margin: 15px auto;
      display: table; }
    .our-menu-special .day-content .purple-content {
      background: #3c1c42;
      width: 100%;
      margin-bottom: 30px;
      color: #f5f5f5; }
      .our-menu-special .day-content .purple-content .name-small {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 26px;
        font-family: "FranklinGothicBookBold", sans-serif; }
      .our-menu-special .day-content .purple-content .name-medium {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 38px;
        font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .purple-content .name-medium small {
          font-size: 18px; }
        .our-menu-special .day-content .purple-content .name-medium span {
          margin-right: 20px;
          float: right; }
        .our-menu-special .day-content .purple-content .name-medium .name-description {
          font-weight: normal;
          font-size: 14px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          text-transform: none; }
      .our-menu-special .day-content .purple-content .name-big {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 48px;
        font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .purple-content .name-big span {
          float: right;
          margin-right: 50px; }
        .our-menu-special .day-content .purple-content .name-big .name-description {
          font-weight: normal;
          font-size: 14px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          text-transform: none; }
    .our-menu-special .day-content .brown-content {
      background: #bf8933;
      width: 100%;
      color: #f5f5f5; }
      .our-menu-special .day-content .brown-content .name-small {
        color: #f5f5f5;
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 26px;
        font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .brown-content .name-small span {
          float: right; }
        .our-menu-special .day-content .brown-content .name-small span.bigger-font {
          font-size: 48px; }
        .our-menu-special .day-content .brown-content .name-small small {
          font-size: 14px; }
        .our-menu-special .day-content .brown-content .name-small .name-description {
          font-weight: normal;
          font-size: 14px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          text-transform: none; }
      .our-menu-special .day-content .brown-content .name-medium {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 32px;
        font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .brown-content .name-medium small {
          font-size: 18px; }
        .our-menu-special .day-content .brown-content .name-medium span {
          margin-right: 20px;
          float: right; }
        .our-menu-special .day-content .brown-content .name-medium span.bigger-font {
          font-size: 48px;
          padding: 10px 0; }
      .our-menu-special .day-content .brown-content .name-big {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 48px;
        font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .brown-content .name-big span {
          float: right;
          margin-right: 50px; }
        .our-menu-special .day-content .brown-content .name-big .name-description {
          font-weight: normal;
          font-size: 14px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          text-transform: none; }
    .our-menu-special .day-content .classic-content {
      width: 100%;
      padding-top: 20px; }
      .our-menu-special .day-content .classic-content .purple-content .name-medium {
        color: #f5f5f5; }
      .our-menu-special .day-content .classic-content .purple-content .name-small {
        color: #f5f5f5;
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 26px;
        font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .classic-content .purple-content .name-small .name-description {
          font-weight: normal;
          font-size: 14px;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          text-transform: none; }
      .our-menu-special .day-content .classic-content .brown-content .name-small {
        color: #f5f5f5;
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 26px;
        font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .classic-content .brown-content .name-small span {
          float: right; }
      .our-menu-special .day-content .classic-content .name-small-list {
        padding: 0 30px; }
        .our-menu-special .day-content .classic-content .name-small-list strong {
          font-size: 18px; }
        .our-menu-special .day-content .classic-content .name-small-list .text-uppercase {
          text-transform: uppercase; }
      .our-menu-special .day-content .classic-content .name-medium {
        padding: 0 30px;
        color: #341905;
        text-transform: uppercase;
        font-weight: bold;
        font-family: "FranklinGothicBookBold", sans-serif;
        font-size: 38px; }
      .our-menu-special .day-content .classic-content .half-width {
        width: 50%;
        display: table-cell; }
        .our-menu-special .day-content .classic-content .half-width ul {
          list-style: none; }
        .our-menu-special .day-content .classic-content .half-width li strong {
          font-size: 16px;
          font-family: "FranklinGothicBookBold", sans-serif; }
      .our-menu-special .day-content .classic-content .list-with-description ul {
        list-style-type: none;
        padding: 30px 30px 0 30px; }
      .our-menu-special .day-content .classic-content .list-with-description ul.small-padding {
        list-style-type: none;
        padding: 0 30px 0 30px; }
        .our-menu-special .day-content .classic-content .list-with-description ul.small-padding li {
          margin-bottom: 10px; }
      .our-menu-special .day-content .classic-content .list-with-description li {
        margin-bottom: 20px; }
      .our-menu-special .day-content .classic-content .list-with-description li strong {
        font-size: 16px;
        font-family: "FranklinGothicBookBold", sans-serif; }
      .our-menu-special .day-content .classic-content .list-with-description li span {
        float: right;
        font-weight: bold; }
      .our-menu-special .day-content .classic-content .list-with-description-long ul {
        list-style-type: none;
        padding: 0 30px; }
      .our-menu-special .day-content .classic-content .list-with-description-long li {
        margin-bottom: 10px; }
      .our-menu-special .day-content .classic-content .list-with-description-long li strong {
        font-size: 16px;
        font-family: "FranklinGothicBookBold", sans-serif; }
      .our-menu-special .day-content .classic-content .list-with-description-long ul ul {
        padding: 0 15px; }
      .our-menu-special .day-content .classic-content .list-with-description-long ul ul li {
        margin-bottom: 0; }
      .our-menu-special .day-content .classic-content .list-centered-bold {
        text-align: center; }
        .our-menu-special .day-content .classic-content .list-centered-bold ul {
          list-style-type: none;
          padding: 0 30px; }
        .our-menu-special .day-content .classic-content .list-centered-bold li {
          font-weight: bold;
          font-size: 18px;
          font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .classic-content .list-centered-bold p {
          margin-top: 15px; }
      .our-menu-special .day-content .classic-content .list-big-with-description ul {
        list-style-type: none; }
      .our-menu-special .day-content .classic-content .list-big-with-description li {
        font-weight: bold;
        font-family: "FranklinGothicBookBold", sans-serif;
        font-size: 28px;
        margin-bottom: 15px; }
        .our-menu-special .day-content .classic-content .list-big-with-description li span {
          float: right;
          margin-right: 30px; }
        .our-menu-special .day-content .classic-content .list-big-with-description li p {
          font-size: 14px; }
      .our-menu-special .day-content .classic-content .three-column-list ul {
        list-style-type: none;
        width: 30%;
        display: table-cell; }
      .our-menu-special .day-content .classic-content table {
        margin: 15px 30px; }
        .our-menu-special .day-content .classic-content table thead {
          font-weight: bold; }
        .our-menu-special .day-content .classic-content table thead td:nth-child(2) {
          padding-right: 8px; }
        .our-menu-special .day-content .classic-content table tbody td:nth-child(2), .our-menu-special .day-content .classic-content table tbody td:nth-child(3) {
          text-align: center; }

/* Responsive */
/* MOBILNI */
@media screen and (max-width: 420px) {
  .franchise-list .col-xs-6 {
    width: 100%; } }

@media screen and (max-width: 980px) {
  section .header-image {
    padding: 20px; }
  section .gray-page {
    padding: 20px; }
  section .slider-bg {
    padding: 20px; }
  section .page {
    padding: 20px; }
  section .location-slider-bg {
    padding: 20px 20px 10px 20px; }
  .header-image-with-text {
    height: 30%;
    width: 100%; }
    .header-image-with-text h1 {
      font-size: 32px; }
  section .page-without-top {
    padding: 0 20px 20px 20px; }
    section .page-without-top h2 {
      padding-top: 0;
      margin-top: 0; }
  .location-list .three-columns-list {
    width: 100%; }
  .location-title h1 {
    font-size: 22px; }
  /* RESPONSIVE OUR MENU */
  .our-menu-special {
    height: auto;
    width: 100%;
    display: block;
    table-layout: fixed;
    /* left */
    /* right */ }
    .our-menu-special .day-name {
      height: 100%;
      display: block;
      width: 100%;
      position: relative;
      background: #341905; }
      .our-menu-special .day-name h2 {
        margin: 0;
        padding: 15px 0;
        letter-spacing: 1px;
        text-align: center;
        color: #f5f5f5;
        font-family: "FranklinGothicBookBold", sans-serif;
        font-size: 38px;
        text-transform: uppercase;
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
    .our-menu-special .day-content {
      width: 100%;
      height: 100%;
      display: block;
      padding: 0;
      background: #fcf9f5; }
      .our-menu-special .day-content .logo {
        margin: 0 auto;
        padding: 15px;
        display: table; }
      .our-menu-special .day-content .purple-content {
        background: #3c1c42;
        width: 100%;
        margin-bottom: 0;
        color: #f5f5f5; }
        .our-menu-special .day-content .purple-content .name-small {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 18px;
          font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .purple-content .name-medium {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 38px;
          font-family: "FranklinGothicBookBold", sans-serif; }
          .our-menu-special .day-content .purple-content .name-medium small {
            font-size: 16px; }
          .our-menu-special .day-content .purple-content .name-medium span {
            margin-right: 0;
            float: right; }
          .our-menu-special .day-content .purple-content .name-medium .name-description {
            font-weight: normal;
            font-size: 14px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: none; }
        .our-menu-special .day-content .purple-content .name-big {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 42px;
          font-family: "FranklinGothicBookBold", sans-serif; }
          .our-menu-special .day-content .purple-content .name-big span {
            float: right;
            margin-right: 0; }
          .our-menu-special .day-content .purple-content .name-big .name-description {
            font-weight: normal;
            font-size: 14px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: none; }
        .our-menu-special .day-content .purple-content .name-big.name-smaller-mob {
          font-size: 28px; }
      .our-menu-special .day-content .brown-content {
        background: #bf8933;
        width: 100%;
        color: #f5f5f5; }
        .our-menu-special .day-content .brown-content .name-small {
          color: #f5f5f5;
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 18px;
          font-family: "FranklinGothicBookBold", sans-serif; }
          .our-menu-special .day-content .brown-content .name-small span {
            float: right; }
          .our-menu-special .day-content .brown-content .name-small span.bigger-font {
            font-size: 38px;
            padding: 5px; }
          .our-menu-special .day-content .brown-content .name-small small {
            font-size: 14px; }
          .our-menu-special .day-content .brown-content .name-small .name-description {
            font-weight: normal;
            font-size: 14px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: none; }
        .our-menu-special .day-content .brown-content .name-medium {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 28px;
          font-family: "FranklinGothicBookBold", sans-serif; }
          .our-menu-special .day-content .brown-content .name-medium small {
            font-size: 16px; }
          .our-menu-special .day-content .brown-content .name-medium span {
            margin-right: 0;
            float: right; }
        .our-menu-special .day-content .brown-content .name-big {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 42px;
          font-family: "FranklinGothicBookBold", sans-serif; }
          .our-menu-special .day-content .brown-content .name-big span {
            float: right;
            margin-right: 0; }
          .our-menu-special .day-content .brown-content .name-big .name-description {
            font-weight: normal;
            font-size: 14px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: none; }
        .our-menu-special .day-content .brown-content .name-big.name-smaller-mob {
          font-size: 28px; }
      .our-menu-special .day-content .classic-content {
        width: 100%;
        padding-top: 10px; }
        .our-menu-special .day-content .classic-content .purple-content .name-medium {
          color: #f5f5f5; }
        .our-menu-special .day-content .classic-content .purple-content .name-small {
          color: #f5f5f5;
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 18px;
          font-family: "FranklinGothicBookBold", sans-serif; }
          .our-menu-special .day-content .classic-content .purple-content .name-small .name-description {
            font-weight: normal;
            font-size: 14px;
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            text-transform: none; }
        .our-menu-special .day-content .classic-content .brown-content .name-small {
          color: #f5f5f5;
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 26px;
          font-family: "FranklinGothicBookBold", sans-serif; }
          .our-menu-special .day-content .classic-content .brown-content .name-small span {
            float: right; }
        .our-menu-special .day-content .classic-content .name-medium {
          padding: 10px;
          color: #341905;
          text-transform: uppercase;
          font-weight: bold;
          font-family: "FranklinGothicBookBold", sans-serif;
          font-size: 28px; }
        .our-menu-special .day-content .classic-content .half-width {
          width: 100%;
          display: table; }
          .our-menu-special .day-content .classic-content .half-width ul {
            padding: 0;
            margin: 15px;
            list-style: none; }
          .our-menu-special .day-content .classic-content .half-width li strong {
            font-size: 16px;
            font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .classic-content .list-with-description ul {
          list-style-type: none;
          padding: 10px; }
        .our-menu-special .day-content .classic-content .list-with-description ul.small-padding {
          list-style-type: none;
          padding: 0 10px; }
          .our-menu-special .day-content .classic-content .list-with-description ul.small-padding li {
            margin-bottom: 10px; }
        .our-menu-special .day-content .classic-content .list-with-description li {
          margin-bottom: 20px; }
        .our-menu-special .day-content .classic-content .list-with-description li strong {
          font-size: 16px;
          font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .classic-content .list-with-description li span {
          float: right;
          font-weight: bold; }
        .our-menu-special .day-content .classic-content .list-with-description-long ul {
          list-style-type: none;
          padding: 0 30px; }
        .our-menu-special .day-content .classic-content .list-with-description-long li {
          margin-bottom: 10px; }
        .our-menu-special .day-content .classic-content .list-with-description-long li strong {
          font-size: 16px;
          font-family: "FranklinGothicBookBold", sans-serif; }
        .our-menu-special .day-content .classic-content .list-with-description-long ul ul {
          padding: 0 15px; }
        .our-menu-special .day-content .classic-content .list-with-description-long ul ul li {
          margin-bottom: 0; }
        .our-menu-special .day-content .classic-content .list-centered-bold {
          text-align: center; }
          .our-menu-special .day-content .classic-content .list-centered-bold ul {
            list-style-type: none;
            padding: 0 30px; }
          .our-menu-special .day-content .classic-content .list-centered-bold li {
            font-weight: bold;
            font-size: 18px;
            font-family: "FranklinGothicBookBold", sans-serif; }
          .our-menu-special .day-content .classic-content .list-centered-bold p {
            margin-top: 15px; }
        .our-menu-special .day-content .classic-content .list-big-with-description ul {
          list-style-type: none;
          margin: 0;
          padding: 15px; }
        .our-menu-special .day-content .classic-content .list-big-with-description li {
          font-weight: bold;
          font-family: "FranklinGothicBookBold", sans-serif;
          font-size: 18px;
          margin-bottom: 10px; }
          .our-menu-special .day-content .classic-content .list-big-with-description li span {
            float: right;
            margin-right: 0; }
          .our-menu-special .day-content .classic-content .list-big-with-description li p {
            font-size: 14px; }
        .our-menu-special .day-content .classic-content .three-column-list ul {
          list-style-type: none;
          width: 100%;
          display: table;
          margin: 0 auto; }
        .our-menu-special .day-content .classic-content table {
          margin: 15px; }
          .our-menu-special .day-content .classic-content table thead {
            font-weight: bold; }
          .our-menu-special .day-content .classic-content table thead td:nth-child(2) {
            padding-right: 8px; }
          .our-menu-special .day-content .classic-content table tbody td:nth-child(2), .our-menu-special .day-content .classic-content table tbody td:nth-child(3) {
            text-align: center; } }

@media screen and (max-width: 740px) {
  .home-headings {
    font-size: 16px; }
  .purple-title-big {
    padding: 10px; }
    .purple-title-big h2 {
      font-size: 16px; }
  .our-menu-seven-days li {
    width: 100%;
    border-right: 0;
    border-bottom: 1px dashed #5d2b66; }
  .our-menu-seven-days li:last-child {
    border-bottom: 0; }
  .gallery figure {
    width: 50%; }
  .promotions-seven {
    display: none;
    width: 100%;
    background: #3c1c42;
    color: #f5f5f5;
    padding: 10px;
    margin-bottom: 10px; }
    .promotions-seven h2 {
      border-right: 1px solid transparent; }
  .starters {
    margin-bottom: 15px; }
    .starters .left {
      width: 100%; }
      .starters .left .big-image-rb {
        margin-top: 0; }
        .starters .left .big-image-rb img {
          border-right: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0; }
      .starters .left .big-image-rt img {
        border-right: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0; }
    .starters .right {
      width: 100%; }
      .starters .right .big-image-lt {
        margin-bottom: 10px; }
        .starters .right .big-image-lt img {
          border-left: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0; }
  .salad-sandwiches .left {
    width: 100%; }
  .salad-sandwiches .right {
    width: 100%; }
    .salad-sandwiches .right .big-image-bl img {
      border-left: 0;
      border-top: 2px solid #bf8933;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
    .salad-sandwiches .right .big-image-lb img {
      border-left: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
  .signature-beverages .left {
    width: 100%; }
    .signature-beverages .left .big-image-rt {
      margin-bottom: 15px; }
      .signature-beverages .left .big-image-rt img {
        border-right: 0;
        border-bottom: 2px solid #bf8933;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0; }
  .signature-beverages .right {
    width: 100%; }
    .signature-beverages .right .big-image-lb img {
      border-left: 0;
      border-top: 2px solid #bf8933;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
    .signature-beverages .right .big-image-ltb img {
      border-left: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
  .after-dinner .left {
    width: 100%; }
  .after-dinner .right {
    width: 100%; }
    .after-dinner .right .normal-image img {
      border-left: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
    .after-dinner .right .normal-image-lb img {
      border-top: 2px solid #bf8933;
      border-left: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
  .breakfast-benedicts .left {
    width: 100%; }
  .breakfast-benedicts .right {
    width: 100%; }
    .breakfast-benedicts .right .big-image-ltb img {
      border-left: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
  .wines .menu-header {
    padding: 10px 15px;
    margin: 10px 15px; }
    .wines .menu-header .wine-image {
      position: relative;
      top: 0;
      right: 0; }
  .wines .menu-content .left-width-10 {
    display: none; }
  .wines .menu-content .right-width-90 {
    width: 100%;
    padding: 0 15px; }
  .hot-and-cold .left {
    width: 100%; }
  .hot-and-cold .right {
    width: 100%; }
  .from-the-bar .left {
    width: 100%; }
    .from-the-bar .left .half-width {
      width: 100%;
      max-width: 100%;
      display: inline-block; }
  .from-the-bar .right {
    width: 100%; }
    .from-the-bar .right .half-width {
      width: 100%;
      max-width: 100%;
      display: inline-block; }
  .from-the-bar .full-width .one-four-width {
    width: 100%;
    max-width: 100%; }
    .from-the-bar .full-width .one-four-width .big-image {
      max-width: 50%;
      width: 50%;
      position: relative;
      float: right; }
  .martinis-menu .left {
    width: 100%; }
  .martinis-menu .right {
    width: 100%; }
  .entrees .left {
    width: 100%; }
    .entrees .left .big-image-trbr img {
      border-right: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0; }
  .entrees .right {
    width: 100%; }
  .parties-to-go .left.parties-to-go-right-bg {
    width: 100%; }
  .parties-to-go .left {
    width: 100%; }
    .parties-to-go .left .footer-menu {
      padding: 0 15px;
      word-wrap: break-word; }
      .parties-to-go .left .footer-menu .social-links a:first-of-type {
        font-size: 14px; }
    .parties-to-go .left .logo img {
      padding: 0 15px; }
  .parties-to-go .right {
    width: 100%; }
    .parties-to-go .right .text-centered.bold {
      padding: 0 15px; }
  .plan-your-party .left.plan-your-party-right-bg {
    width: 100%; }
  .plan-your-party .left {
    width: 100%; }
  .plan-your-party .right {
    width: 100%; }
  .take-out-2 .left.take-out-right-bg {
    width: 100%; }
  .take-out-2 .left {
    width: 100%; }
    .take-out-2 .left .social-links a:first-of-type {
      font-size: 14px; }
  .take-out-2 .right {
    width: 100%; }
  .take-out-1 .left {
    width: 100%; }
  .take-out-1 .right {
    width: 100%; } }

@media screen and (max-width: 520px) {
  .top-right-nav li {
    border: 0 !important; }
  .sub-navigation li {
    width: 100% !important; }
  .franchise-heading {
    margin-top: 15px; } }

@media screen and (max-width: 980px) {
  .locations-list .three-columns-list {
    width: 100%; } }

@media screen and (max-width: 1200px) {
  .nav-top .top-right-nav {
    max-width: 85%; }
    .nav-top .top-right-nav li {
      float: right;
      padding: 0; }
    .nav-top .top-right-nav li:first-child {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent; }
    .nav-top .top-right-nav li a {
      font-size: 14px;
      padding: 10px; }
  .sub-navigation li {
    width: 50%; }
  .sub-navigation li:last-child {
    border-right: 1px dashed #5d2b66; }
  .top-nav-left {
    margin: 5px 0 !important; }
  .signature-wrapper figure h2 {
    font-size: 14px !important;
    font-weight: normal !important;
    padding: 10px 0 !important; }
  .slick-list:before {
    background: transparent !important; }
  .slick-list:after {
    background: transparent !important; }
  .get-social {
    bottom: 7px;
    text-align: center; }
  .get-social-list {
    text-align: center;
    display: inline-block;
    padding: 0 15px; }
  .get-social-list li {
    display: inline;
    margin-right: 7px;
    margin-bottom: 7px;
    background: transparent; }
  header .logo img {
    display: block;
    max-width: 100%;
    height: auto; }
  .col-lg-4.yellow-bg {
    display: inline-block;
    margin: 0 15px; }
  .row-eq-height {
    display: block; }
  .location-banner {
    height: auto; }
  .location-for-you-list {
    width: 100%; }
  .location-widget {
    display: inline-block;
    width: 100%;
    margin-top: 0; }
  .widget address {
    text-align: center; }
  .widget span:first-of-type {
    text-align: center;
    display: table;
    margin: 0 auto; }
  .widget .location-map {
    margin-bottom: 10px;
    height: 250px;
    width: 100%; }
  .location-socials .locations-reviews-list {
    float: none;
    width: 100%; }
  .location-socials .social-widgets li {
    padding: 0;
    display: block;
    max-width: 100%;
    margin: 0 auto;
    list-style-type: none; }
  .location-socials .social-widgets iframe {
    max-width: 220px !important; }
  .location-socials .social-widgets #CDSSCROLLINGRAVE.narrow {
    max-width: 220px !important; }
  .current-opportunities .header {
    padding: 15px; }
    .current-opportunities .header h2 {
      text-align: center;
      float: none;
      max-width: 100%;
      font-size: 38px;
      line-height: 38px; }
    .current-opportunities .header .logo {
      float: none;
      max-width: 100%;
      padding: 10px 30px; }
  .current-opportunities .content {
    padding: 15px; }
    .current-opportunities .content ul {
      font-size: 18px;
      width: 100%;
      max-width: 100%;
      float: none;
      padding: 0 15px; }
  .current-opportunities .full-width {
    position: relative; }
    .current-opportunities .full-width .border-box {
      padding: 15px;
      width: 100%;
      float: none;
      position: relative;
      right: 0;
      bottom: 0;
      background: #fef4e5;
      border: 3px solid #341905;
      -webkit-border-top-left-radius: 0;
      -webkit-border-top-right-radius: 0;
      -moz-border-radius-topleft: 0;
      -moz-border-radius-topright: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
      .current-opportunities .full-width .border-box ul {
        font-size: 20px;
        font-weight: bold;
        padding: 0;
        font-family: "FranklinGothicBookBold", sans-serif; }
        .current-opportunities .full-width .border-box ul li {
          width: 100%;
          display: block; }
        .current-opportunities .full-width .border-box ul li:before {
          content: '•';
          margin-right: 10px; }
  .current-opportunities .awards-texts {
    display: table;
    position: relative; }
    .current-opportunities .awards-texts ul {
      max-width: 100%;
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .current-opportunities .awards-texts ul li {
        float: left;
        display: inline;
        width: 50%;
        padding: 5px; }
        .current-opportunities .awards-texts ul li img {
          width: 100%; }
  .current-opportunities .informations {
    margin-top: 15px;
    padding: 15px;
    display: block;
    width: 100%;
    text-align: center;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-size: 18px; } }

/* Navigation */
.responsive-nav {
  display: none; }

.top-left-list li a {
  border-right: 1px dashed #2b2b2b;
  width: 100%;
  display: block;
  padding: 9px 15px; }
  .top-left-list li a:hover {
    background: #2b2b2b; }

.top-left-list li:first-child {
  border-left: 1px dashed #2b2b2b; }

@media screen and (max-width: 1200px) {
  .centered {
    display: block; }
  /* .top-nav-left {
     position: relative;
     z-index: 1;
     width: 48px;
     height: 48px;
     color: $white-link;
     background-color: lighten($black, 20%) !important;
     overflow: hidden;
     border-radius: 3px;
     cursor: pointer;
     float: left;
     margin: 10px 0;
     max-width: 15%;
     li a, li:first-child {
       border: 0;
       width: 100%;
     }
     @media screen and (min-width: 1200px) {
       position: static;
       width: auto;
       height: auto;
       background: none;
       color: $white-link;
     }

     &:after {
       content: "☰";
       font-size: $font-size-default+2;
       width: 1em;
       line-height: 1;
       text-align: center;
       position: absolute;
       top: 50%;
       left: 50%;
       margin-top: - (16px / 2);
       margin-left: - (16px / 2);

       @media screen and (min-width: 1200px) {
         content: normal;
         content: none;
       }

     }

     &.is-open {
       overflow: visible;
       &:after {
         content: "✕";
         @media screen and (min-width: 1200px) {
           content: normal;
           content: none;
         }
       }
     }
   }*/
  /*.top-left-list {
    list-style: none;
    margin: 0;
    padding: 48px 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: lighten($black, 10%);
    display: inline-block;
    border-radius: 3px;
    z-index: -1;
    li {
      background: $white-link;
      width: 100%;
    }
    li a {
      width: 100%;
      display: block;
      padding: 5px 15px;
    }
    li a:hover {
      background: lighten($black, 10%);
    }
  }*/ }

/*
@media screen and (min-width: 1200px) {
  .top-left-list {
    padding-top: 0;
    width: auto;
    display: block;
    background: none;
    text-align: left;
  }
*/
/* Master navigatioin */
a.open {
  display: none;
  float: left;
  margin-top: 15px;
  color: #f5f5f5;
  font-family: "FranklinGothicBookBold", sans-serif;
  text-transform: uppercase;
  font-size: "Helvetica Neue", Helvetica, Arial, sans-serif6;
  padding: 15px 20px;
  text-align: center;
  background: #3c1c42;
  border: 0; }

#pageslide {
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  background-color: #111;
  color: #f5f5f5;
  overflow-y: scroll;
  border-right: 3px solid #bf8933; }

.hidden-pc {
  display: none; }

@media only screen and (max-width: 980px) {
  .top-nav-left, .top-right-nav {
    display: none;
    border: 0;
    margin: 0;
    padding: 0;
    float: none; }
  .width100 {
    width: 100%;
    display: inline-block; }
  .responsive-nav {
    display: block;
    background: #111;
    padding-bottom: 100px; }
    .responsive-nav .logo img {
      margin: 0 auto;
      display: table; }
  #nav {
    display: none; }
  a.open {
    margin-left: 30px;
    display: block;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px; }
  #pageslide {
    width: 220px;
    margin: 0; }
    #pageslide .logo {
      display: none; }
    #pageslide .padding-logo-nav {
      padding: 0; }
    #pageslide nav {
      margin: 0; }
    #pageslide .hidden-pc {
      display: block; }
  #pageslide nav ul {
    padding: 0 15px; }
  #pageslide nav li {
    padding: 10px 0;
    width: 100%; }
  #pageslide nav ul ul {
    display: none; }
  #pageslide nav ul li:hover ul {
    display: none; }
  #pageslide #nav nav li a {
    width: 100%;
    color: #f5f5f5; }
  #pageslide #nav nav li a:hover {
    color: #bf8933; } }

.list-padding0 {
  padding-left: 0 !important; }

.content-in-middle-pd {
  padding: 0 30px 15px 30px; }

.our_menu-take-out {
  margin-bottom: 30px; }
  .our_menu-take-out.bg-black {
    background: black !important;
    color: white; }
    .our_menu-take-out.bg-black small {
      color: white; }
  .our_menu-take-out.bg-gray {
    background: #e4e5e9 !important; }
  .our_menu-take-out:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-take-out .left-side, .our_menu-take-out .right-side {
    float: left;
    width: 50%;
    padding: 30px; }
    .our_menu-take-out .left-side:after, .our_menu-take-out .right-side:after {
      content: '';
      display: block;
      clear: both; }
  .our_menu-take-out .sub-title {
    font-weight: bold; }
  .our_menu-take-out ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative; }
    .our_menu-take-out ul li {
      position: relative;
      display: block;
      text-align: right; }
      .our_menu-take-out ul li:after {
        content: '';
        display: block;
        clear: both; }
      .our_menu-take-out ul li span {
        display: inline-block; }
        .our_menu-take-out ul li span:first-child {
          float: left; }
      .our_menu-take-out ul li img {
        width: 20px;
        position: absolute;
        left: -25px;
        top: 0; }
        .our_menu-take-out ul li img.none-position {
          position: relative;
          top: auto;
          left: auto; }
  .our_menu-take-out .social-links img {
    width: auto !important;
    position: relative !important;
    top: auto !important;
    left: auto !important; }

@media screen and (max-width: 720px) {
  .our_menu-take-out .left-side, .our_menu-take-out .right-side {
    width: 100% !important; } }

.our_menu-plan-your-party {
  margin-bottom: 30px; }
  .our_menu-plan-your-party.bg-black {
    background: black !important;
    color: white; }
    .our_menu-plan-your-party.bg-black small {
      color: white; }
  .our_menu-plan-your-party.bg-gray {
    background: #e4e5e9 !important; }
  .our_menu-plan-your-party:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-plan-your-party .left-side, .our_menu-plan-your-party .right-side {
    float: left;
    width: 50%;
    padding: 30px;
    position: relative; }
    .our_menu-plan-your-party .left-side:after, .our_menu-plan-your-party .right-side:after {
      content: '';
      display: block;
      clear: both; }
  .our_menu-plan-your-party .sub-title {
    font-weight: bold; }
  .our_menu-plan-your-party ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative; }
    .our_menu-plan-your-party ul li {
      position: relative;
      display: block;
      text-align: right; }
      .our_menu-plan-your-party ul li:after {
        content: '';
        display: block;
        clear: both; }
      .our_menu-plan-your-party ul li span {
        display: inline-block; }
        .our_menu-plan-your-party ul li span:first-child {
          float: left;
          text-align: left; }
      .our_menu-plan-your-party ul li p {
        text-align: left; }
        .our_menu-plan-your-party ul li p span {
          display: inline-block; }
          .our_menu-plan-your-party ul li p span:first-child {
            float: left; }
          .our_menu-plan-your-party ul li p span.cals {
            float: right; }
      .our_menu-plan-your-party ul li img {
        width: 20px;
        position: absolute;
        left: -25px;
        top: 0; }
        .our_menu-plan-your-party ul li img.none-position {
          position: relative;
          top: auto;
          left: auto; }
  .our_menu-plan-your-party .purple-ribbon {
    background: #3c1c42;
    padding: 5px 10px;
    position: absolute;
    left: 0;
    right: 0;
    color: white; }

.parties-to-go {
  height: 900px;
  width: 100%;
  display: table;
  table-layout: fixed;
  position: relative;
  background: #e4e5e9;
  margin-bottom: 15px; }

.parties-to-go .menu-container {
  float: left;
  width: 100%;
  position: relative;
  right: 50%; }

.parties-to-go .left {
  float: left;
  width: 50%;
  position: relative;
  left: 50%;
  overflow: hidden;
  vertical-align: top;
  height: 100%;
  color: #111;
  background: #e4e5e9; }

.parties-to-go .left .purple-colored {
  color: #7d3a8a;
  float: none;
  font-weight: 700; }

.parties-to-go .left .logo {
  margin: 30px auto;
  display: table;
  max-width: 100%; }

.parties-to-go .left .footer-menu {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  color: #f5f5f5;
  text-align: center; }

.parties-to-go .left .footer-menu h2:first-of-type {
  font-size: 38px; }

.parties-to-go .left .footer-menu h2:first-of-type, .parties-to-go .left .footer-menu h3 {
  text-align: center;
  font-family: FranklinGothicBookBold, sans-serif;
  color: #f5f5f5;
  font-weight: 700; }

.parties-to-go .left .footer-menu h3 {
  font-size: 28px;
  padding: 5px 10px; }

.parties-to-go .left .footer-menu a.big-link {
  text-align: center;
  margin: 10px auto;
  display: table;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #f5f5f5; }

.parties-to-go .left h2 {
  font-family: FranklinGothicBookBold, sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  padding: 5px 20px; }

.parties-to-go .left h2 small {
  font-size: 14px;
  float: right;
  vertical-align: text-bottom;
  color: #111; }

.parties-to-go .left h3 {
  padding: 0 20px;
  font-family: FranklinGothicBookBold, sans-serif;
  font-weight: 700;
  font-size: "Helvetica Neue", Helvetica, Arial, sans-serif4; }

.parties-to-go .left ul {
  padding: 0 20px;
  list-style-type: none; }

.parties-to-go .left ul li span {
  float: right; }

.parties-to-go .left .text-centered {
  text-align: center; }

.parties-to-go .left .line-dashed {
  height: 1px;
  margin: 20px 0;
  border-top: 1px dashed #fff; }

.parties-to-go .left.parties-to-go-right-bg {
  min-height: 1100px;
  background: #111 url(../../images/our-menu/parties-to-go.jpg) no-repeat 50%; }

.parties-to-go .left.parties-to-go-right-bg, .parties-to-go .right {
  float: left;
  width: 50%;
  position: relative;
  left: 50%;
  overflow: hidden;
  vertical-align: top;
  height: 100%;
  color: #111; }

.parties-to-go .right {
  background: #e4e5e9; }

.parties-to-go .right .logo {
  margin: 30px auto;
  display: table; }

.parties-to-go .right .footer-menu {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  color: #f5f5f5;
  text-align: center; }

.parties-to-go .right .footer-menu h1 {
  font-size: 38px; }

.parties-to-go .right .footer-menu h1, .parties-to-go .right .footer-menu h3 {
  text-align: center;
  font-family: FranklinGothicBookBold, sans-serif;
  color: #f5f5f5;
  font-weight: 700; }

.parties-to-go .right .footer-menu h3 {
  font-size: 28px;
  padding: 5px 10px; }

.parties-to-go .right .footer-menu a.big-link {
  text-align: center;
  margin: 10px auto;
  display: table;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #f5f5f5; }

.parties-to-go .right h2 {
  font-family: FranklinGothicBookBold, sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  padding: 5px 20px; }

.parties-to-go .right h2 small {
  font-size: 14px;
  float: right;
  vertical-align: text-bottom;
  color: #111; }

.parties-to-go .right h3 {
  padding: 0 20px;
  font-family: FranklinGothicBookBold, sans-serif;
  font-weight: 700;
  font-size: "Helvetica Neue", Helvetica, Arial, sans-serif4; }

.parties-to-go .right ul {
  padding: 0 20px;
  list-style-type: none; }

.parties-to-go .right ul li span {
  float: right; }

.parties-to-go .right .text-centered {
  text-align: center; }

.parties-to-go .right .line-dashed {
  height: 1px;
  margin: 20px 0;
  border-top: 1px dashed #fff; }

.parties-to-go .right.parties-to-go-right-bg {
  width: 100%;
  height: 100%;
  display: inline;
  background: #111 url(../../images/our-menu/parties-to-go.jpg) no-repeat 50%; }

.our_menu-kids {
  position: relative; }
  .our_menu-kids .title {
    position: absolute;
    margin-top: -90px;
    background: #3c1c42;
    width: 100%;
    color: white;
    padding: 15px 20px; }
    .our_menu-kids .title h1 {
      padding: 0;
      margin: 0;
      font-weight: bold; }
      .our_menu-kids .title h1 small {
        float: right;
        color: white; }
    .our_menu-kids .title p {
      margin: 0;
      font-size: 12px; }

.our_menu-entrees {
  position: relative;
  display: block; }
  .our_menu-entrees::after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-entrees.bg-gray {
    background-color: #e4e5e9; }
  .our_menu-entrees ul {
    padding: 0;
    list-style: none; }
  .our_menu-entrees p,
  .our_menu-entrees span {
    font-size: 12px; }
  .our_menu-entrees .cals,
  .our_menu-entrees .price {
    float: right; }
  .our_menu-entrees .price {
    margin-right: 5px; }
  .our_menu-entrees li {
    position: relative; }
  .our_menu-entrees li strong {
    font-size: 14px; }
  .our_menu-entrees .header_background {
    background: black;
    width: 100%;
    height: 90px;
    padding: 5px; }
  .our_menu-entrees .header_background h1 strong {
    color: #ffe9ca;
    font-size: 50px;
    padding: 0 45px; }
  .our_menu-entrees .left-side,
  .our_menu-entrees .right-side {
    float: left;
    width: 50%;
    padding: 30px;
    display: block; }
  .our_menu-entrees .left-side:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-entrees .right-side:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-entrees .middle_content {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 0 30px; }
  .our_menu-entrees .middle_content h2:after,
  .our_menu-entrees .middle_content h2:before {
    content: '';
    width: 30%;
    border-bottom: solid 1px black;
    position: absolute;
    margin-top: 15px; }
  .our_menu-entrees .middle_content h2:after {
    left: 0; }
  .our_menu-entrees .middle_content h2:before {
    right: 0; }
  .our_menu-entrees .middle_content h2 {
    width: auto;
    position: relative; }
  .our_menu-entrees .purple {
    color: #3C1C42;
    text-align: left; }
  .our_menu-entrees .small_strong_text {
    font-size: 12px; }
  .our_menu-entrees .image-top {
    max-width: 400px;
    margin-top: -24px;
    margin-right: -30px;
    float: right; }
  .our_menu-entrees .image-shape {
    max-width: 225px;
    display: block;
    margin: 20px -50px 0 0;
    shape-outside: url(/images/our-menu/meal-left-side.svg);
    shape-image-threshold: 0.5;
    float: right; }
  .our_menu-entrees .side_rectangle {
    background-color: black;
    padding: 10px;
    margin-top: -130px; }
  .our_menu-entrees .side_rectangle strong {
    color: #ffd79c; }
  @media screen and (max-width: 720px) {
    .our_menu-entrees .left-side,
    .our_menu-entrees .right-side {
      width: 100% !important; }
    .our_menu-entrees .middle_content h2:after,
    .our_menu-entrees .middle_content h2:before {
      width: 20%; }
    .our_menu-entrees .image-top {
      max-width: 300px;
      margin-bottom: 30px; }
    .our_menu-entrees .side_rectangle {
      margin-top: -30px; } }
  .our_menu-entrees .icon-symp-gluten {
    width: 20px;
    position: absolute;
    left: -25px;
    top: 0; }
  .our_menu-entrees .abs-top-20 {
    top: 20px; }
  @media screen and (max-width: 470px) {
    .our_menu-entrees .middle_content h2:after,
    .our_menu-entrees .middle_content h2:before {
      width: 10%; }
    .our_menu-entrees .image-shape {
      float: none;
      margin-bottom: 5px; } }

.wines {
  height: 900px;
  display: table;
  table-layout: fixed;
  margin-bottom: 15px; }

.wines, .wines .full-width {
  width: 100%;
  position: relative;
  background: #e4e5e9; }

.wines .full-width {
  display: inline-block;
  float: none;
  height: 100%;
  color: #111; }

.wines .menu-name {
  width: 100%;
  position: relative;
  color: #f5f5f5;
  overflow: hidden;
  background: #111; }

.wines .menu-name h1 {
  font-size: 38px;
  text-align: left;
  font-family: FranklinGothicBookBold, sans-serif;
  color: #f5f5f5;
  font-weight: 700;
  padding: 15px 20px;
  margin: 0; }

.wines .menu-header {
  border: 1px solid #111;
  border-radius: 15px;
  position: relative;
  padding: 10px 20px;
  margin: 10px 20px; }

.wines .menu-header h2 {
  font-size: 32px;
  font-weight: 700; }

.wines .menu-header h2, .wines .menu-header h3 {
  color: #111;
  font-family: FranklinGothicBookBold, sans-serif;
  font-style: italic;
  padding: 0;
  margin: 0; }

.wines .menu-header h3 {
  font-size: 28px; }

.wines .menu-header .wine-image {
  position: absolute;
  right: 20px;
  top: -90px; }

.wines .menu-header .wine-image img {
  max-width: 270px;
  border: 2px solid #bf8933;
  border-radius: 5px; }

.wines .menu-header table {
  width: 100%;
  padding: 0 20px; }

.wines .menu-header table tbody td:nth-child(2), .wines .menu-header table tbody td:nth-child(3), .wines .menu-header table thead tr {
  text-align: right; }

.wines .menu-content {
  width: 100%;
  position: relative;
  display: block; }

.wines .menu-content .left-width-10 {
  width: 8%;
  float: left;
  display: inline;
  position: relative; }

.wines .menu-content .left-width-10 .rotated-name {
  margin-top: 300px;
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  color: #111;
  font-family: FranklinGothicBookBold, sans-serif;
  font-style: italic;
  font-size: 32px;
  text-transform: uppercase; }

.wines .menu-content .left-width-10 .rotated-name:before {
  content: " ";
  position: absolute;
  left: 150px;
  top: 20px;
  width: 100%;
  height: 1px;
  background: #000; }

.wines .menu-content .left-width-10 .rotated-name:after {
  content: " ";
  position: absolute;
  right: 100px;
  top: 20px;
  width: 100%;
  height: 1px;
  background: #000; }

.wines .menu-content .right-width-90 {
  width: 92%;
  float: left;
  display: inline;
  position: relative;
  padding: 20px; }

.wines .menu-content table {
  width: 100%; }

.wines .menu-content table tbody td:nth-child(2), .wines .menu-content table tbody td:nth-child(3), .wines .menu-content table tbody td:nth-child(4), .wines .menu-content table thead td:nth-child(2), .wines .menu-content table thead td:nth-child(3), .wines .menu-content table thead td:nth-child(4) {
  text-align: center; }

.wines .menu-content table thead td:nth-child(2), .wines .menu-content table thead td:nth-child(3), .wines .menu-content table thead td:nth-child(4) {
  font-weight: 700; }

.wines .menu-content .purple-colored {
  text-align: right;
  color: #7d3a8a;
  font-style: italic; }

.our_menu-desserts {
  position: relative;
  display: block;
  padding-top: 30px; }
  .our_menu-desserts:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-desserts.bg-gray, .our_menu-desserts .bg-gray {
    background-color: #e4e5e9; }
  .our_menu-desserts ul {
    padding: 0;
    list-style: none; }
    .our_menu-desserts ul li {
      position: relative;
      text-align: left; }
  .our_menu-desserts .icons {
    width: 20px;
    position: absolute;
    left: -25px;
    top: 0; }
  .our_menu-desserts p,
  .our_menu-desserts span {
    font-size: 12px; }
  .our_menu-desserts .cals,
  .our_menu-desserts .price {
    float: right; }
  .our_menu-desserts .price {
    margin-right: 5px; }
  .our_menu-desserts li strong {
    font-size: 14px; }
  .our_menu-desserts .header_background {
    background: black;
    width: 100%;
    height: 90px;
    padding: 5px; }
  .our_menu-desserts h1 {
    margin-top: 15px; }
  .our_menu-desserts .header_background h1 strong {
    color: #ffe9ca;
    font-size: 50px;
    padding: 0 45px; }
  .our_menu-desserts .left-side,
  .our_menu-desserts .right-side {
    float: left;
    width: 50%;
    padding: 30px;
    display: block; }
  .our_menu-desserts .left-side:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-desserts .right-side:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-desserts .middle_content {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 0 30px; }
  .our_menu-desserts .purple {
    color: #3C1C42;
    font-size: 11px; }
  .our_menu-desserts .image_responsive_none {
    display: table;
    margin: auto;
    max-width: 100%; }
  .our_menu-desserts .small_strong_text {
    font-size: 12px; }
  .our_menu-desserts .middle_text {
    text-align: center;
    font-size: 11px; }
  .our_menu-desserts .image-shape {
    max-width: 150px;
    display: block;
    float: right;
    margin: 35px 0 35px 10px; }
  .our_menu-desserts .image-top {
    position: absolute;
    top: -10px;
    right: 0; }
  .our_menu-desserts .image-middle {
    margin-bottom: 20px; }
  @media screen and (max-width: 720px) {
    .our_menu-desserts .left-side,
    .our_menu-desserts .right-side {
      width: 100% !important; }
    .our_menu-desserts .image-shape {
      max-width: 120px; }
    .our_menu-desserts .image-top {
      max-width: 300px;
      position: absolute;
      top: 70px;
      right: 0; } }
  @media screen and (max-width: 360px) {
    .our_menu-desserts .image-top {
      max-width: 200px;
      position: absolute;
      top: 90px;
      right: 0; }
    .our_menu-desserts .image-shape {
      width: 100%;
      margin: 10px 30px; }
    .our_menu-desserts .header_background h1 strong {
      font-size: 40px;
      padding: 20px; } }

.our_menu-starters {
  margin-bottom: 30px;
  padding-top: 20px; }
  .our_menu-starters.bg-black {
    background: black !important;
    color: white; }
    .our_menu-starters.bg-black small {
      color: white; }
  .our_menu-starters.bg-gray {
    background: #e4e5e9 !important; }
  .our_menu-starters:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-starters .full-container {
    width: 100%;
    display: table; }
    .our_menu-starters .full-container.bg-black {
      background: black !important;
      color: white; }
    .our_menu-starters .full-container img.img-responsive {
      max-width: 100%;
      position: absolute; }
    .our_menu-starters .full-container h3 {
      margin-top: 0;
      padding-top: 0; }
    .our_menu-starters .full-container h4 {
      margin-bottom: 0; }
  .our_menu-starters .left-side, .our_menu-starters .right-side {
    position: relative;
    float: left;
    width: 50%;
    padding: 30px; }
    .our_menu-starters .left-side:after, .our_menu-starters .right-side:after {
      content: '';
      display: block;
      clear: both; }
  .our_menu-starters ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative; }
    .our_menu-starters ul li {
      position: relative;
      display: block; }
      .our_menu-starters ul li:after {
        content: '';
        display: block;
        clear: both; }
      .our_menu-starters ul li span {
        float: right; }
      .our_menu-starters ul li .icon-symp, .our_menu-starters ul li .icon-symp-gluten {
        width: 20px;
        position: absolute;
        left: -25px;
        top: 0; }
  .our_menu-starters .starters-img-right {
    margin-top: -70px; }
  .our_menu-starters .left-container {
    margin: 20px -30px 0 -30px;
    width: 100%;
    padding-left: 20px;
    position: absolute; }
  .our_menu-starters .full-starters-container {
    position: relative; }
    .our_menu-starters .full-starters-container img.img-responsive {
      margin-top: -100px;
      width: 40%; }
    .our_menu-starters .full-starters-container h2 {
      margin-left: 42%; }
  .our_menu-starters .bordered-box-radius {
    padding: 10px;
    border: 2px solid black;
    display: block;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px; }
    .our_menu-starters .bordered-box-radius p {
      margin: 0;
      font-size: 12px; }

.our_menu-burgers {
  position: relative;
  display: block;
  padding-top: 30px; }
  .our_menu-burgers:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-burgers.bg-gray, .our_menu-burgers .bg-gray {
    background-color: #e4e5e9; }
  .our_menu-burgers.bg-black {
    background: black !important;
    color: white; }
  .our_menu-burgers ul {
    padding: 0;
    list-style: none; }
    .our_menu-burgers ul li {
      position: relative;
      text-align: left; }
  .our_menu-burgers h1, .our_menu-burgers h2.imitate-h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 42px; }
  .our_menu-burgers .icons {
    width: 20px;
    position: absolute;
    left: -25px;
    top: 0; }
  .our_menu-burgers p,
  .our_menu-burgers span {
    font-size: 12px; }
  .our_menu-burgers .cals,
  .our_menu-burgers .price {
    float: right; }
  .our_menu-burgers .price {
    margin-right: 5px; }
  .our_menu-burgers li strong {
    font-size: 14px; }
  .our_menu-burgers .full-container {
    width: 100%;
    display: table;
    padding: 0 30px; }
    .our_menu-burgers .full-container.reset-padding {
      padding: 0 15px; }
    .our_menu-burgers .full-container.reset-padding0 {
      padding: 0; }
    .our_menu-burgers .full-container.yellow-bg {
      color: #3c1c42;
      font-weight: bold;
      border-top: 2px solid #3c1c42;
      border-bottom: 2px solid #3c1c42;
      background: #ffe9ca;
      text-align: center;
      padding: 10px 15px; }
    .our_menu-burgers .full-container.bg-black {
      background: black !important;
      color: white; }
    .our_menu-burgers .full-container img.img-responsive {
      position: absolute;
      max-width: 80%;
      margin-top: -15px;
      right: -30px;
      top: 0; }
    .our_menu-burgers .full-container h3 {
      margin-top: 0;
      padding-top: 0; }
    .our_menu-burgers .full-container h4 {
      margin-bottom: 0; }
  .our_menu-burgers .left-side, .our_menu-burgers .right-side {
    position: relative;
    float: left;
    width: 50%;
    padding: 30px; }
    .our_menu-burgers .left-side:after, .our_menu-burgers .right-side:after {
      content: '';
      display: block;
      clear: both; }
  .our_menu-burgers .small-text-list {
    font-size: 12px; }
  .our_menu-burgers .column-one-four {
    padding: 0 0 0 10px;
    width: 25%;
    float: left; }
    .our_menu-burgers .column-one-four:after {
      content: '';
      display: block;
      clear: both; }
    .our_menu-burgers .column-one-four .price {
      float: none;
      margin-left: 5px; }
  .our_menu-burgers .menu-burgers-img-left-fixed {
    left: -30px;
    max-width: 100% !important; }
  .our_menu-burgers .menu-burgers-img-right-fixed {
    max-width: 100%;
    margin-top: -60px;
    right: -30px; }
  .our_menu-burgers .additional-text-mtop60 {
    margin-top: 60px; }
  @media screen and (max-width: 720px) {
    .our_menu-burgers .left-side, .our_menu-burgers .right-side {
      width: 100% !important;
      padding: 15px; }
    .our_menu-burgers .additional-text-mtop60 {
      margin-top: 0; }
    .our_menu-burgers .full-container img.img-responsive {
      max-width: 100%;
      position: relative;
      margin: 0; }
    .our_menu-burgers .menu-burgers-img-left-fixed {
      left: -45px !important; }
    .our_menu-burgers .menu-burgers-img-right-fixed {
      right: -45px !important; }
    .our_menu-burgers .column-one-four {
      width: 100%; } }

.our_menu-martinis {
  position: relative;
  display: block;
  overflow: hidden; }
  .our_menu-martinis:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-martinis.bg-gray, .our_menu-martinis .bg-gray {
    background-color: #e4e5e9; }
  .our_menu-martinis.bg-black {
    background: black !important;
    color: white; }
  .our_menu-martinis ul {
    padding: 0;
    list-style: none; }
    .our_menu-martinis ul li {
      position: relative;
      text-align: left; }
  .our_menu-martinis h1, .our_menu-martinis h2.imitate-h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 42px; }
  .our_menu-martinis p,
  .our_menu-martinis span {
    font-size: 12px; }
  .our_menu-martinis .cals,
  .our_menu-martinis .price {
    float: right; }
  .our_menu-martinis .price {
    margin-right: 5px; }
  .our_menu-martinis li strong {
    font-size: 14px; }
  .our_menu-martinis .our-menu-title-centered {
    text-align: center;
    padding: 15px !important; }
    .our_menu-martinis .our-menu-title-centered .img-responsive {
      width: auto;
      display: table;
      margin: 0 auto; }
  .our_menu-martinis .full-container {
    width: 100%;
    display: table;
    padding: 0 30px; }
    .our_menu-martinis .full-container.bg-black {
      background: black !important;
      color: white; }
  .our_menu-martinis .left-side-70, .our_menu-martinis .right-side-70 {
    position: relative;
    float: left;
    width: 70%;
    padding: 30px; }
    .our_menu-martinis .left-side-70:after, .our_menu-martinis .right-side-70:after {
      content: '';
      display: block;
      clear: both; }
  .our_menu-martinis .left-side-30, .our_menu-martinis .right-side-30 {
    position: relative;
    float: left;
    width: 30%;
    padding: 30px; }
    .our_menu-martinis .left-side-30:after, .our_menu-martinis .right-side-30:after {
      content: '';
      display: block;
      clear: both; }
  .our_menu-martinis .martinis-right-image {
    max-width: 100%; }
  .our_menu-martinis .martinis-left-image {
    position: absolute;
    left: -50px;
    width: 120%; }
  @media screen and (max-width: 768px) {
    .our_menu-martinis .full-container {
      padding: 0 15px; }
    .our_menu-martinis .left-side-70, .our_menu-martinis .right-side-70, .our_menu-martinis .left-side-30, .our_menu-martinis .right-side-30 {
      width: 100%;
      padding: 15px; }
    .our_menu-martinis .martinis-left-image {
      position: absolute;
      left: auto;
      right: -15px;
      width: 20%; }
    .our_menu-martinis .martinis-right-image {
      display: table;
      margin: 0 auto;
      max-width: 60%; } }
  @media screen and (max-width: 670px) {
    .our_menu-martinis .martinis-left-image {
      display: none; } }

.our_menu-beverages {
  position: relative;
  display: block; }
  .our_menu-beverages:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-beverages.bg-gray {
    background-color: #e4e5e9; }
  .our_menu-beverages ul {
    padding: 0;
    list-style: none; }
  .our_menu-beverages li {
    text-align: left;
    position: relative; }
  .our_menu-beverages p,
  .our_menu-beverages span {
    font-size: 12px;
    line-height: 16px; }
  .our_menu-beverages li {
    line-height: 20px;
    display: block; }
    .our_menu-beverages li:after {
      content: '';
      display: block;
      clear: both; }
  .our_menu-beverages .icons {
    width: 20px;
    position: absolute;
    left: -25px;
    top: 0; }
  .our_menu-beverages .cals,
  .our_menu-beverages .price {
    float: right; }
  .our_menu-beverages .price {
    margin-right: 5px; }
  .our_menu-beverages li strong {
    font-size: 14px; }
  .our_menu-beverages li h3 {
    padding: 0;
    margin: 0; }
  .our_menu-beverages h3 strong {
    font-size: 24px;
    text-align: left;
    margin: 0 auto;
    vertical-align: middle; }
  .our_menu-beverages .header_on_top {
    width: 100%; }
  .our_menu-beverages .margin_top {
    top: 50px; }
  .our_menu-beverages .header_background {
    background: black;
    width: 100%;
    height: 100px;
    position: relative;
    display: table; }
  .our_menu-beverages .header_background h1 strong {
    color: #ffe9ca;
    margin: 0 auto;
    position: relative;
    z-index: 10; }
  .our_menu-beverages .header_background h2 {
    vertical-align: middle; }
  .our_menu-beverages .header_background h1 {
    vertical-align: middle;
    margin-left: 30px; }
  .our_menu-beverages h2 strong {
    color: #ffe9ca;
    margin: 0 30px;
    font-size: 32px;
    position: relative;
    z-index: 10; }
  .our_menu-beverages .header_background:first-child {
    top: 60px; }
  .our_menu-beverages .left-side,
  .our_menu-beverages .right-side {
    float: left;
    position: relative;
    width: 50%;
    padding: 30px;
    display: block; }
  .our_menu-beverages .left-side:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-beverages .right-side:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-beverages .middle_content {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 0 30px; }
  .our_menu-beverages .purple {
    color: #3C1C42;
    font-size: 11px; }
  .our_menu-beverages .image_responsive_none {
    display: table;
    margin: auto;
    max-width: 100%; }
  .our_menu-beverages .img_right {
    float: right;
    margin: -90px -30px 30px auto;
    position: relative;
    max-width: 350px; }
  .our_menu-beverages .small_strong_text {
    font-size: 12px; }
  .our_menu-beverages .middle_text {
    text-align: center;
    font-size: 11px; }
  .our_menu-beverages .image-shape {
    max-width: 150px;
    display: block;
    float: right;
    margin: 35px 0 35px 10px; }
  .our_menu-beverages .image-top {
    position: absolute;
    top: -10px;
    right: 0; }
  .our_menu-beverages .image-middle {
    margin-bottom: 20px; }
  @media screen and (max-width: 720px) {
    .our_menu-beverages .left-side,
    .our_menu-beverages .right-side {
      width: 100% !important; }
    .our_menu-beverages .left-side {
      padding-bottom: 0; }
    .our_menu-beverages .right-side {
      padding-top: 0; }
    .our_menu-beverages .img_right {
      max-width: 100%;
      float: none;
      margin: 0 auto; }
    .our_menu-beverages .header_background {
      display: inline-block; }
      .our_menu-beverages .header_background:first-child {
        top: 30px !important; }
    .our_menu-beverages .image-top {
      width: 100px; }
    .our_menu-beverages .header_on_top {
      position: relative; }
    .our_menu-beverages h1 {
      margin: 30px; }
    .our_menu-beverages h2 strong {
      float: left;
      margin-bottom: 10px; }
    .our_menu-beverages .left-side .image_responsive_none {
      margin-top: 0; } }
  @media screen and (max-width: 360px) {
    .our_menu-beverages .right-side {
      padding-top: 0; } }

.event-price-list {
  display: flex;
  margin: 0 auto; }
  .event-price-list .left {
    width: 25%;
    position: relative;
    background: #341905; }
  .event-price-list .right {
    width: 75%; }
  .event-price-list .rotated h1 {
    letter-spacing: 5px;
    color: #f5f5f5;
    font-family: "FranklinGothicBookBold", sans-serif;
    font-size: 100px;
    text-transform: uppercase;
    transform: rotate(90deg);
    white-space: nowrap;
    margin-top: 150px; }
  .event-price-list .rotated {
    text-align: center; }
  .event-price-list .price {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: 30px auto;
    color: white;
    font-size: 32px; }
  .event-price-list .menu_title {
    padding: 30px 30px 20px 30px;
    margin-top: -150px; }
    .event-price-list .menu_title h2 {
      font-size: 38px;
      font-weight: bold;
      color: #871778;
      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; }
    .event-price-list .menu_title h3 {
      font-size: 28px;
      color: black;
      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; }
  .event-price-list .choice-white {
    padding: 20px 30px;
    background: white;
    color: #871778; }
    .event-price-list .choice-white span {
      font-size: 24px;
      font-weight: bold;
      display: block; }
  .event-price-list .choice-rose {
    background: #f3b3bb;
    padding: 20px 30px; }
    .event-price-list .choice-rose span {
      font-size: 24px;
      font-weight: bold;
      display: block; }
    .event-price-list .choice-rose ul {
      margin: 0;
      padding: 0;
      list-style-type: none; }
  .event-price-list .choice-dessert {
    padding: 20px 30px;
    background: white;
    color: #871778; }
    .event-price-list .choice-dessert span {
      font-size: 24px;
      font-weight: bold;
      display: block; }
    .event-price-list .choice-dessert ul {
      margin: 0;
      padding: 0;
      list-style-type: none; }
  @media screen and (max-width: 992px) {
    .event-price-list .menu_title {
      padding: 30px 20px 20px 20px; }
      .event-price-list .menu_title h2 {
        font-size: 34px; }
      .event-price-list .menu_title h3 {
        font-size: 20px; } }
  @media screen and (max-width: 600px) {
    .event-price-list {
      display: block;
      margin: 0 auto; }
      .event-price-list .right, .event-price-list .left {
        width: 100%; }
      .event-price-list .rotated h1 {
        letter-spacing: inherit;
        font-size: 48px;
        transform: inherit;
        margin-top: 0; }
      .event-price-list .price {
        position: relative;
        text-align: center;
        margin: 15px auto;
        font-size: 24px; } }
  @media screen and (max-width: 400px) {
    .event-price-list .rotated h1 {
      font-size: 30px !important; }
    .event-price-list .menu_title {
      margin-top: -70px;
      padding: 15px; }
      .event-price-list .menu_title h2 {
        margin: 0; }
    .event-price-list .choice-dessert, .event-price-list .choice-rose, .event-price-list .choice-white {
      padding: 15px; } }

.bg-custom-take-out {
  background: url("/images/our-menu/take-out-left-side.jpg") no-repeat left center;
  min-height: 1200px;
  position: relative; }
  @media screen and (max-width: 720px) {
    .bg-custom-take-out {
      max-height: 100%; } }

.bottom-containers {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center; }
  .bottom-containers .bottom-white-row {
    padding: 15px;
    color: #333;
    background: rgba(255, 255, 255, 0.7); }
    .bottom-containers .bottom-white-row small {
      color: #333; }
  .bottom-containers .bottom-purple-row {
    padding: 15px;
    background: #5d2b66; }

.left-side-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
  padding: 30px 15px;
  background: rgba(0, 0, 0, 0.5); }
  .left-side-footer .social-links a:first-of-type {
    margin-bottom: 15px;
    display: block; }
  .left-side-footer .social-links ul {
    margin: 0 auto; }

.our_menu-early {
  position: relative;
  max-width: 550px;
  margin: 0 auto;
  display: block;
  overflow: hidden; }
  .our_menu-early:after {
    content: '';
    display: block;
    clear: both; }
  .our_menu-early.bg-gray, .our_menu-early .bg-gray {
    background-color: #e4e5e9; }
  .our_menu-early.bg-black {
    background: black !important;
    color: white; }
  .our_menu-early ul {
    padding: 0;
    list-style: none; }
    .our_menu-early ul li {
      position: relative;
      text-align: left; }
  .our_menu-early h1, .our_menu-early h2.imitate-h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 42px; }
  .our_menu-early p,
  .our_menu-early span {
    font-size: 12px; }
  .our_menu-early .cals,
  .our_menu-early .price {
    float: right; }
  .our_menu-early .price {
    margin-right: 5px; }
  .our_menu-early li strong {
    font-size: 14px; }
  .our_menu-early .our-menu-title-centered {
    text-align: center;
    padding: 15px !important; }
    .our_menu-early .our-menu-title-centered .img-responsive {
      width: auto;
      display: table;
      margin: 0 auto; }
  .our_menu-early .full-container {
    width: 100%;
    display: table;
    padding: 0 30px; }
    .our_menu-early .full-container.bg-black {
      background: black !important;
      color: white; }

.gallery.contest figure {
  width: 100%;
  position: absolute;
  left: 0;
  margin: auto;
  top: 0;
  right: 0; }

@media screen and (max-width: 780px) {
  .gallery.contest figure {
    width: 100%; } }

.contest-list li {
  margin-bottom: 10px; }
