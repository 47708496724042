/* Same height */
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
}

.row .row {
  margin-top: 0;
  margin-bottom: 15px;
}

.row .locations-list .row {
  margin-bottom: 0;
}

/* Fonts */
@font-face {
  font-family: 'FranklinGothicBookBold';
  src: url('../../fonts/FRABK.woff');
}

/* Defaults */
$font-family-header: "FranklinGothicBookBold", sans-serif;
$font-family-default: "Helvetica Neue", Helvetica, Arial, sans-serif;
$black: #111;
$dark-brown: #341905;
$white-link: #f5f5f5;
$brown-link: #bf8933;
$yellow-bg: #fef4e5;
$purple-bg: #3c1c42;
$our-menu-bg-1: #e4e5e9;
$font-color-on-black: #ebe7e5;
$font-size-default-smaller: 12px;
$font-size-default: 14px;
$font-size-franklin-h1: 38px;
$font-size-franklin-h2: 32px;
$font-size-franklin-h3: 28px;
$font-size-h1: 30px;
$font-size-h2: 24px;
$font-size-h3: 20px;
$font-size-navigation: 20px;
$pix32: 32px;

%list-style-inline-ul {
  margin: 0;
  padding: 0;
}

%list-style-block-li {
  margin: 0;
  padding: 0;
  list-style: none;
}

%list-style-inline-li-nav {
  float: left;
  display: inline;
  list-style: none;
}

%border-radius-img {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

body {
  background: url("../../images/bg.png");
  font-family: $font-family-default;
  color: #333;
  line-height: 1.4;
  overflow-y: scroll;
  font-size: $font-size-default;
}

a, img {
  -webkit-transition: all .3s ease-in-out;
  -khtml-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 10px;
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

p {
  margin: 0 0 10px 0;
  line-height: 1.5;
}

address {
  font-family: $font-family-default;
}

form fieldset {
  border: 1px solid lighten($black, 80%);
  padding: 0 15px 15px 15px;
  margin: 10px 0;
}

form.feedback-form fieldset {
  border: 1px solid lighten($black, 10%);
  padding: 0 25px 25px 25px;
  margin: 10px 0;
}

form.contact-form fieldset {
  border: 1px solid lighten($brown-link, 30%);
  padding: 0 25px 25px 25px;
  margin: 10px 0;
}

form.contact-form:hover fieldset {
  border: 1px solid lighten($brown-link, 10%);

}

form legend {
  background: transparent;
  padding: 0 10px;
  border-bottom: none;
  width: auto;
  font-family: $font-family-header;
  font-weight: bold;
  font-size: $font-size-default+2;
}

form label {
  margin-top: 10px;
}

form sup {
  color: red;
}

.ul-in-ul {
  padding-left: 15px;
  ul {
    padding-left: 15px;
    list-style-type: square;
  }
}

.table > thead {
  background: lighten($purple-bg, 0%);
  color: $white-link;
}

.table > thead > tr > th {
  border: none;
}

.table > tbody > tr:nth-child(even) {
  background: lighten($brown-link, 50%);
}

.table > tbody > tr:hover {
  background: lighten($brown-link, 40%);

}

.clear {
  clear: both;
}

.bigger-h2 {
  font-size: $font-size-franklin-h2;
}

.uppercase {
  text-transform: uppercase;
}

.font-franklin {
  font-family: $font-family-header;
}

.margin-bottom0 {
  margin-bottom: 0 !important;
}

.margin-top0 {
  margin-top: 0 !important;
}

.margin-right0 {
  margin-right: 0 !important;
}

.margin-top10 {
  margin-top: 10px !important;
}

.margin-top-5 {
  margin-top: -5px !important;
}

.margin-top-15 {
  margin-top: -15px;
}

.margin-top-80 {
  margin-top: -80px;
}

.margin-top-120 {
  margin-top: -120px;
}

.margin-top60 {
  margin-top: 60px !important;
}

.padding-top0 {
  padding-top: 0 !important;
}

.padding-bottom30 {
  padding-bottom: 15px !important;
}

.padding-right15 {
  padding-right: 15px !important;
}

.padding0 {
  padding: 0 !important;
}

.capitalize {
  text-transform: capitalize;
}

.brown-text {
  color: $brown-link;
}

.bold {
  font-weight: bold;
}

.justify {
  text-align: justify;
}

.centered {
  float: none;
  margin: 0 auto;
  display: table;
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.right {
  float: right;
}

.yellow-bg {
  background: $yellow-bg;
}

.purple-text {
  color: $purple-bg !important;
}

.blue-text {
  color: #5675d0 !important;
}

.col-lg-4.yellow-bg {
  margin-left: -15px;
}

.line {
  width: 100%;
  height: 1px;
  background: lighten($black, 80%);
  margin-bottom: 10px;
}

.line-dashed {
  height: 1px;
  margin: 20px 0;
  border-top: 1px dashed lighten($black, 80%);
}

.img-responsive {
  width: 100%;
}

.img-symposium {
  position: relative;
  margin-bottom: 15px;
}

.img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .1) 50%, rgba(0, 0, 0, .3) 80%, rgba(0, 0, 0, .7) 100%);
  z-index: 1;
}

.img-name {
  font-size: $font-size-franklin-h3;
  font-family: $font-family-header;
  color: $white-link;
  position: absolute;
  left: 0;
  bottom: 45px;
  background: rgba($black, 0.7);
  padding: 5px 10px;
  z-index: 1;
}

.img-name.smaller {
  font-size: $font-size-default+4px;
  bottom: 30px;
}

.affix, .affix-top {
  position: static
}

#static {
  max-width: 320px;
  position: relative;
}

@media screen and (min-width: 1200px) {
  #static.affix-top {
    position: static
  }

  #static.affix {
    position: fixed;
    top: 10px;
    width: 100%
  }
}

.title-h3 {
  font-size: 26px;
  font-family: $font-family-header;
  color: $brown-link;
  font-weight: bold;
  margin-top: 5px;
}

/* Basic */

/* Header */
.black-header {
  background: $black;
  padding-bottom: 100px;
}

.nav-top {
  border-bottom: 1px dashed lighten($black, 10%);
  font-family: $font-family-header;
  a {
    color: lighten($black, 50%);
    display: block;

    letter-spacing: 1px;
    text-transform: uppercase;
    &:hover {
      color: $brown-link;
    }
  }
  .top-left-nav {
    @extend %list-style-inline-ul;
  }
  .top-left-nav li {
    padding: 9px 15px;
    @extend %list-style-inline-li-nav;

    border-right: 1px dashed lighten($black, 10%);
    &:hover {
      background: lighten($black, 10%);
    }
  }
  .top-left-nav li:first-child {
    border-left: 1px dashed lighten($black, 10%);
  }

  .top-right-nav {
    float: right;
    @extend %list-style-inline-ul;
  }
  .top-right-nav li {
    @extend %list-style-inline-li-nav;
    font-weight: bold;
    border-right: 1px dashed lighten($black, 10%);
    border-left: 1px dashed lighten($black, 10%);
    a {
      padding: 7px 15px;
      font-size: $font-size-default+2;
    }
  }
}

.padding-logo-nav {
  padding: 20px 0;
}

header .logo {
  display: inline;
  float: left;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

nav {
  float: right;
  margin-top: 30px;
  position: relative;

  ul {
    @extend %list-style-inline-ul
  }
  li {
    @extend %list-style-inline-li-nav;
    margin-right: 12px;
    position: relative;

  }
  li ul {
    display: none;
    position: relative;
  }
  li:hover ul {
    display: block;
    position: absolute;
    background: $white-link;
    top: 35px;
    margin: 0;
    list-style: none;
    -moz-column-count: 2;
    -moz-column-gap: 0;
    -webkit-column-count: 2;
    -webkit-column-gap: 0;
    column-count: 2;
    column-gap: 0;
    z-index: 99999;
    padding: 0;
    border-top: 3px solid $brown-link;
    li {
      width: 100%;
      display: inline-block;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid-column;
      a {
        font-size: $font-size-default+2;
        padding: 5px 10px;
        color: $purple-bg;
        font-weight: bold;
        font-family: $font-family-header;
        width: 100%;
        display: block;
      }
      a:hover, a.active {
        border: 0;
        background: $purple-bg;
        color: $white-link;
      }
      li:hover {
        border: 0;
      }

    }
  }

  li:last-child {
    margin-right: 0;
  }

  a {
    font-family: $font-family-header;
    color: $font-color-on-black;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  a:hover, a.active {
    color: $brown-link;
    padding-bottom: 10px;
    border-bottom: 3px solid #bf8933;
  }

}

/* Page */
/* Default page style */
section .header-image {
  margin-top: -80px;
  position: relative;
  width: 100%;
  background: #fff;
  padding: 40px 40px 0 40px;

}

.header-image-with-text {
  width: 50%;
  height: 5%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.header-image-with-text h1 {
  color: $white-link;
  font-family: $font-family-header;
  /*border-bottom: 1px solid $white-link;*/

  display: inline;
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: $font-size-franklin-h1+12;
  background: rgba(0, 0, 0, 0.3);

  padding: 8px 20px;
}

.header-image img {
  width: 100%;
  max-height: 380px;
}

/** Home **/

section .slider-bg {
  background: #fff;
  padding: 40px;
  margin-top: -80px;
}

section .gray-page {
  background: #f1f1f1;
  padding: 0;
}

.home-headings {
  margin: 0 0 15px 0;
  padding: 0;
  text-align: center;
  font-weight: bold;
  font-family: $font-family-header;
  font-size: $font-size-franklin-h3;
  text-transform: uppercase;
}

.decor {
  margin: 0 15px;
  position: relative;
  .decor-bg {
    background: #18181a url('../../images/decor-big.jpg') 50% 60% no-repeat;
    background-size: cover;
    height: 300px;
    border-bottom: 3px solid $brown-link;
  }

  p {
    position: absolute;
    text-align: center;
    top: 20px;
    left: 0;
    right: 0;
    padding: 10px;
    font-size: $font-size-default+4;
    font-family: $font-family-default;
    font-style: italic;
    color: $white-link;
    background: rgba(0, 0, 0, 0.6);
  }
}

.tp-caption.slider-purple {
  font-family: $font-family-header;
  text-decoration: none;

  color: $white-link;
  white-space: nowrap;
  font-size: $font-size-franklin-h1+10;
  background: $purple-bg;
}

.tp-caption.slider-purple span {
  padding: 5px 15px !important;
  font-weight: bold;
}

.tp-caption.slider-purple a {
  color: $white-link;
}

.purple-title-big {
  background: $purple-bg;
  margin: 0;
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
}

.purple-title-big h2 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: $font-size-h1;
  font-family: $font-family-header;
  color: #fff;
}

section .page {
  background: #fff;
  padding: 40px;
}

.abs-border {
  margin: 0 auto;
  position: relative;
  z-index: 99;
  overflow: hidden;
}

.abs-border img {
  width: 100%;
  min-width: 100%;
}

.abs-border:before {
  display: block;
  content: '';
  position: absolute;
  top: 8px;
  z-index: 99;
  right: 8px;
  bottom: 8px;
  left: 8px;
  border: 1px solid lighten($brown-link, 30%);
}

.abs-border:hover:before {
  display: block;
  content: '';
  position: absolute;
  z-index: 99;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  border: 1px solid $brown-link
}

.abs-border:hover img.abs-border-img {
  -webkit-transform: rotate(5deg) scale(1.1); /* Chrome 4+, Op 15+, Saf 3.1, iOS Saf 3.2+ */
  -moz-transform: rotate(5deg) scale(1.1); /* Fx 3.5-15 */
  -ms-transform: rotate(5deg) scale(1.1); /* IE 9 */
  -o-transform: rotate(5deg) scale(1.1); /* Op 10.5-12 */
  transform: rotate(5deg) scale(1.1); /* Fx 16+, IE 10+ */
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  -khtml-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

/* MINI ABS BORDER */
.abs-border-mini {
  margin: 0 auto;
  position: relative;
  z-index: 99;
  overflow: hidden;
}

.abs-border-mini img {
  width: 100%;
  min-width: 100%;
}

.abs-border-mini:before {
  display: block;
  content: '';
  position: absolute;
  top: 5px;
  z-index: 99;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 1px solid lighten($brown-link, 30%);
}

.abs-border-mini:hover:before {
  display: block;
  content: '';
  position: absolute;
  z-index: 99;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 1px solid $brown-link
}

.abs-border-mini:hover img.abs-border-img {
  -webkit-transform: rotate(5deg) scale(1.1); /* Chrome 4+, Op 15+, Saf 3.1, iOS Saf 3.2+ */
  -moz-transform: rotate(5deg) scale(1.1); /* Fx 3.5-15 */
  -ms-transform: rotate(5deg) scale(1.1); /* IE 9 */
  -o-transform: rotate(5deg) scale(1.1); /* Op 10.5-12 */
  transform: rotate(5deg) scale(1.1); /* Fx 16+, IE 10+ */
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  -khtml-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.get-social {
  position: absolute;
  z-index: 9999;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 30px;
}

.get-social-list {
  @extend %list-style-inline-ul;
  display: table;
  margin: 0 auto;
}

.get-social-list li {
  @extend %list-style-inline-li-nav;
  margin-right: 7px;
  background: transparent;

}

.get-social-list li:hover {
  -webkit-box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
  -moz-box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
  box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.get-social-list img {
  max-width: 32px;
}

/* Locations */
section .page-without-top {
  padding: 0 40px 40px 40px;
  background: #fff;
}

.no-margin {
  margin: 0;
}

.no-margin-top {
  margin-top: 0;
}

.locations-list {
  border: 1px solid $brown-link;
  .two-columns-list {
    padding: 0;
    margin: 0;
    list-style: none;
    -moz-column-count: 2;
    -moz-column-gap: 0;
    -webkit-column-count: 2;
    -webkit-column-gap: 0;
    column-count: 2;
    column-gap: 0;
  }
  .three-columns-list {
    padding: 0;
    width: 33.3334%;
    margin: 0;
    list-style: none;
    float: left;
  }

  .two-columns-list li, .locations-list .three-columns-list li {
    border-bottom: 1px solid $white-link;
    width: 100%;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    display: table;
  }
  .two-columns-list li a, .three-columns-list li a {
    font-size: 20px;
    padding: 5px 25px;
    color: $purple-bg;
    font-weight: bold;
    font-family: $font-family-header;
    width: 100%;
    display: block;
  }

  .two-columns-list li a:hover, .three-columns-list li a:hover {
    color: lighten($purple-bg, 20%);
    background: lighten($black, 90%);
  }
  h3 {
    text-transform: uppercase;
    font-family: $font-family-header;
    font-size: $font-size-franklin-h3;
  }
}

.locations-list .opening-soon-list {
  @extend %list-style-inline-ul;
  list-style-type: none;
}

.locations-list .opening-soon-list li {
  cursor: default;
  font-size: 20px;
  padding: 5px 25px;
  color: $purple-bg;
  font-weight: bold;
  font-family: $font-family-header;
  border-bottom: 1px solid $white-link;
}

.locations-list .opening-soon-list li:hover {
  background: darken($yellow-bg, 10%);
}

/* About Us */
.about-us {
  p {
    margin-bottom: 0;
  }
  .line-dashed {
    height: 1px;
    margin: 10px 0;
    border-top: 1px dashed lighten($black, 80%);
  }
}

.height365 {
  max-height: 365px;
}

.image-in-paragraph {
  display: inline;
  padding: 5px;
  .abs-border {
    display: block;
  }
  .text {
    display: block;
    text-align: right;
    font-size: $font-size-default-smaller;
    color: $black;
  }
}

.image-in-paragraph.right {
  float: right;
}

.image-in-paragraph.left {
  float: left;
  .text {
    text-align: left;
  }
}

/** Location **/
section .location-slider-bg {
  margin-top: -80px;
  position: relative;
  width: 100%;
  z-index: 1;
  background: #fff;
  padding: 40px 40px 30px 40px;
}

.location-banner {
  height: 362px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
  }
}

.location-title {
  background: $purple-bg;
  width: 100%;
  padding: 15px 0;
  margin-bottom: 5px;
  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    color: $white-link;
    font-weight: bold;
    font-family: $font-family-header;
    text-transform: uppercase;
    font-size: $font-size-franklin-h2;
  }
  a {
    color: $white-link;
  }
}

.promotions-seven {
  display: none;
  width: 100%;
  background: $purple-bg;
  color: $white-link;
  padding: 10px;
  margin-bottom: 10px;
  h2 {
    font-size: $font-size-default+4;
    font-family: $font-family-header;
    border-right: 1px solid $brown-link;
    margin: 0;
    padding: 0 10px;
    display: inline;
    float: left;
  }
  .content-of-day {
    padding-left: 10px;
    overflow: hidden;
  }
}

.location-for-you-list {
  border-top: 1px dashed lighten($brown-link, 20%);
  margin-top: 10px;
  padding: 10px 0;
  list-style: none;
  float: left;
  width: 50%;

  li {
    margin-bottom: 5px;
  }
  li:before {
    color: $brown-link;
    content: "~";
    font-size: $font-size-default+4;
    margin-right: 5px;
  }
}

.location-widget {
  z-index: 999;
  margin-top: -80px;
  margin-right: -5px;
  .yellow-bg {
    z-index: 999;
    padding: 15px;
    -webkit-box-shadow: -1px 13px 53px -12px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 13px 53px -12px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 13px 53px -12px rgba(0, 0, 0, 0.75);
  }
}

.widget.contact {
  padding: 15px;
  h3 {
    margin: 0 0 15px 0;
    padding: 0;
    text-align: center;
  }
}

.widget {
  width: 100%;
  display: inline-block;
  time {

    display: block;
  }
  time span {
    float: right;
    font-weight: bold;
  }
  .location-map {
    margin-bottom: 10px;
    height: 180px;
    width: 100%;
  }
}

.widget-social-list {
  @extend %list-style-inline-ul;
  display: table;
  margin: 0 auto;

  li {
    @extend %list-style-inline-li-nav;
    margin-right: 10px;
    background: transparent;
  }
  li:last-child {
    margin-right: 0;
  }
  li:hover {
    -webkit-box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
    -moz-box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);
    box-shadow: -1px 1px 29px 10px rgba(191, 137, 51, 0.75);

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }

  img {
    max-width: 32px;
  }
}

.widget-services-list {

  ul {
    @extend %list-style-inline-ul;
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      text-align: center;
      margin: 5px 7px;
    }

  }
}

.paymentAccepted {
  text-align: center;
}

.location-socials {
  margin: 30px 0;
  padding: 0 15px;
  border: 1px solid $brown-link;
  font-style: italic;
  .locations-reviews-list {
    list-style: none;
    float: left;
    width: 50%;
    border-top: 1px dashed lighten($brown-link, 20%);
    margin-top: 10px;
    padding: 10px 0;

    li:before {
      color: $brown-link;
      content: "★";
      font-size: $font-size-default+4;
      margin-right: 5px;
    }
  }
  .social-widgets {
    @extend %list-style-inline-ul;
    display: table;
    margin: 0 auto;
    li {
      text-align: center;
      display: table;
      margin: 10px auto 0 auto;
      list-style-type: none;

    }
  }
}

.location-maps {

  iframe {
    width: 100%;
    height: 365px;
    margin: 0;
    padding: 0;
  }
  .location-box-text {
    background: $yellow-bg;
    color: $black;
    padding: 7px 10px;
    border: 1px solid $brown-link;
    margin: 0;
  }
}

/* Franchise */
.sub-navigation {
  display: inline-block;
  position: relative;
  width: 100%;
  background: $purple-bg;
}

.sub-navigation ul {
  padding: 0;
  list-style-type: none;
  margin: 0 auto;
  display: table;

}

.sub-navigation li {
  text-align: center;
  border-right: 1px dashed lighten($purple-bg, 10%);
  @extend %list-style-inline-li-nav;
}

.sub-navigation li:last-child {
  border-right: 1px solid transparent;
}

.sub-navigation li.clone-href {
  text-transform: uppercase;
  color: $white-link;
  display: block;
  font-size: $font-size-default;
  border-right: 1px dashed lighten($purple-bg, 10%);
  padding: 7px 10px;
  cursor: default;
}

.sub-navigation li a {
  text-transform: uppercase;
  color: $white-link;
  display: block;
  font-size: $font-size-default;
  padding: 7px 10px;
}

.sub-navigation li a:hover, .sub-navigation li a.active {
  display: block;
  background: lighten($purple-bg, 10%);
}

.autoplay {
  .slick-list:before {
    background: transparent !important;
  }

  .slick-list:after {
    background: transparent !important;
  }
  .cfa-images {
    img {
      padding: 15px;
    }
  }
}

.franchise-list ul {
  padding-left: 20px;
}

.franchise-heading {
  font-size: $font-size-default+4;
  font-weight: bold;
}

a .franchise-heading {
  color: $black;
}

a .franchise-heading:hover {
  color: $brown-link;
}

.panel-default > .panel-heading {
  border-bottom: 1px solid lighten($brown-link, 40%);
  background: lighten($brown-link, 50%);
}

.panel-default > .panel-heading:hover {
  background: lighten($brown-link, 40%);
}

.panel-title {
  margin: 0;
  padding: 10px 15px;
}

.panel-title a {
  color: $brown-link;
}

.panel-body {
  padding: 15px 30px;
}

.current-opportunities {
  .header {
    background: $dark-brown;
    color: $white-link;
    display: table;
    padding: 30px;
    width: 100%;
    height: 100%;
    margin: 0;
    h2 {
      margin: 0;
      padding: 0;
      float: left;
      text-transform: uppercase;
      font-family: $font-family-header;
      font-size: $font-size-franklin-h1+15;
      font-weight: bold;
      max-width: 50%;
      line-height: $font-size-franklin-h1+15;
    }
    .logo {
      float: right;
      max-width: 100%;
    }
  }
  .content {
    width: 100%;
    margin: 0;
    padding: 30px 30px 15px 30px;
    background: $our-menu-bg-1;
    display: table;
    h2.location-name {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $font-size-franklin-h1+5;
      font-size: $font-size-franklin-h1;
      color: $dark-brown;
    }
    ul {
      width: 50%;
      max-width: 50%;
      float: left;
      padding: 30px 45px 0 45px;
      font-weight: bold;
      font-size: $font-size-franklin-h3;
      font-family: $font-family-header;
    }
  }
  .images {
    width: 100%;
    .half-width {
      width: 50%;
      float: left;
    }
    .border-6303-l {
      border-right: 6px solid $white-link;
      border-bottom: 6px solid $white-link;
      border-top: 9px solid $white-link;
    }
    .border-6303-r {
      border-left: 6px solid $white-link;
      border-bottom: 6px solid $white-link;
      border-top: 9px solid $white-link;
    }
    .border-3000 {
      border-top: 6px solid $white-link;
    }
  }
  .full-width {
    position: relative;
    .border-box {
      padding: 15px;
      width: 45%;
      float: right;
      position: absolute;
      right: 30px;
      bottom: -125px;
      background: $yellow-bg;
      border-top: 3px solid $dark-brown;
      border-left: 3px solid $dark-brown;
      border-right: 3px solid $dark-brown;
      -webkit-border-top-left-radius: 15px;
      -webkit-border-top-right-radius: 15px;
      -moz-border-radius-topleft: 15px;
      -moz-border-radius-topright: 15px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      ul {
        font-size: $font-size-default+6;
        font-weight: bold;
        padding: 0;
        font-family: $font-family-header;
        li {
          width: 100%;
          display: block;
        }
        li:before {
          content: '•';
          margin-right: 10px;
        }
      }
    }
  }
  .awards-texts {
    display: table;
    position: relative;
    ul {
      max-width: 60%;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        float: left;
        display: inline;
        width: 25%;
        padding: 5px;
        img {
          width: 100%;
        }

      }
    }

  }
  .informations {
    padding: 15px;
    color: $white-link;
    background: $dark-brown;
    display: table;
    width: 100%;
    text-align: right;
    font-size: $font-size-default+10;
  }
}

/* Widget */
.widget {
  padding: 15px 0;
}

.widget .header-h2 {
  text-align: center;
  display: table;
  margin: 0 auto;
}

.widget h2 {
  padding: 0 0 15px;
  margin: 0 0 15px 0;
  float: left;
  display: inline-block;
  font-family: 'FranklinGothicBookBold', sans-serif;
  font-weight: bold;
  border-bottom: 1px solid lighten($brown-link, 20%);
}

.border-bottom {
  width: 50%;
  height: 1px;
  background: lighten($brown-link, 20%);
  margin: 0 auto 10px auto;
}

.widget .menu-categories {
  padding: 0;
  margin-top: 15px;
}

.widget .menu-categories li {
  list-style-type: none;
  margin-bottom: 5px;
}

.widget .menu-categories ul {
  margin-left: 15px;
  padding: 0;
}

.widget .menu-categories ul li {
  margin-bottom: 0;
}

.widget .menu-categories ul a {
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  padding: 4px 15px;
  font-weight: normal;
  display: block;
  color: $black;
}

.widget .menu-categories a {
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  padding: 8px 15px;
  display: block;
  font-weight: bold;
  color: $black;
}

.widget .menu-categories a:hover, .widget .menu-categories a.active {
  background: $purple-bg;
  color: $white-link;
}

/* Gallery */
.gallery-content h1 {
  margin: 0;
  padding: 0;
  font-family: $font-family-header;
  font-size: $font-size-franklin-h3;
}

.gallery {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative
}

.gallery:after {
  display: block;
  height: 0;
  content: '';
  clear: both;
}

.gallery figure a {
  display: block;
  margin: 7px;
  border: 1px solid lighten($black, 80%);
}

.gallery figure a:hover {
  border: 1px solid $purple-bg;
}

.gallery figure {
  float: left;
  /*clear: left;*/
  width: 33.3334%;
  opacity: .9;
  position: relative;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent
}

.gallery figure:hover {
  opacity: 1
}

.gallery figure img {
  display: block;
  width: 100%;
}

.gallery figure:before {
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, .35) 0, rgba(0, 0, 0, 0) 80%);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity
}

.gallery figure:active, .gallery figure:focus, .gallery figure:hover {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
  -webkit-animation-name: hover;
  animation-name: hover;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate
}

.gallery figure:active:before, .gallery figure:focus:before, .gallery figure:hover:before {
  opacity: .4;
  -webkit-transform: translateY(6px);
  transform: translateY(6px);
  -webkit-animation-name: hover-shadow;
  animation-name: hover-shadow;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate
}

.gallery .gallery-description {
  display: none;
}

/* Forms */
.slider-range {
  margin-top: 25px;
}

/* Footer */

footer a {
  color: lighten($black, 50%);
}

footer a:hover {
  color: lighten($black, 80%);
}

footer a.yellow {
  color: $brown-link;
}

footer a.yellow:hover {
  color: lighten($brown-link, 30%);
}

.black-footer {
  background: $black;
  color: lighten($black, 50%);
  font-size: 13px;
  padding: 20px 0;
}

.footer-logo img {
  max-width: 190px;
  width: 190px;
  margin-bottom: 10px;
}

.footer-socials {
  margin-top: 20px;
  .col-xs-2, .col-xs-10 {
    padding: 0;
    margin: 0;
  }
  .small_icon {
    margin: 0 auto;
    display: table;
    text-align: center;
    line-height: 32px;
    img {
      float: left;
      display: inline;
      max-height: 32px;
    }
    span {
      margin-left: 10px;
    }
  }

  .instagram {
    float: left;
    display: inline;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .instagram img {
    max-height: 46px;
  }
}

small.smaller {
  font-size: 9px;
}

.footer-title {
  font-weight: bold;
  color: $white-link;
  font-size: $font-size-default;
  margin-bottom: 20px;
}

.footer-title small {
  color: lighten($black, 50%);
  font-weight: normal;
}

.footer-list {
  @extend %list-style-inline-ul
}

.footer-list li {
  @extend %list-style-block-li;
  margin-bottom: 15px;
  font-size: $font-size-default;
}

.footer-contact-us {
  @extend %list-style-inline-ul;
}

.footer-contact-us li {
  @extend %list-style-block-li;
  margin-bottom: 15px;
  font-size: $font-size-default;
}

.footer-contact-us li:last-child, .footer-list li:last-child {
  margin-bottom: 0;
}

/****************** OUR MENU ******************/
.normalized-h1 {
  margin: 0;
  padding: 0;
  font-family: $font-family-header;
  font-size: $font-size-franklin-h3;
}

/* 7 Days */
.our-menu-seven-days {
  display: inline-block;
  position: relative;
  width: 100%;
  background: $purple-bg;
  ul {
    padding: 0;
    list-style-type: none;
    margin: 0 auto;
    display: table;

  }
  li {
    text-align: center;
    border-right: 1px dashed lighten($purple-bg, 10%);
    @extend %list-style-inline-li-nav;
  }

  li:last-child {
    border-right: 1px solid transparent;
  }

  li a {
    text-transform: uppercase;
    color: $white-link;
    font-family: $font-family-header;
    display: block;
    font-size: $font-size-default+2;
    padding: 7px 10px;
    text-decoration: none;
  }

  li a:hover, li a.active, li a:focus, li a:active {
    display: block;
    text-decoration: none;
    background: lighten($purple-bg, 10%);
  }

}

.our-menu-special {
  height: 900px;
  width: 100%;
  display: table;
  table-layout: fixed;
  /* left */
  .day-name {
    height: 900px;
    display: table-cell;
    width: 25%;
    position: relative;
    background: $dark-brown;
    h2 {
      margin-left: 20px;
      letter-spacing: 7px;
      color: $white-link;
      font-family: $font-family-header;
      font-size: 132px;
      text-transform: uppercase;
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  /* right */
  .day-content {
    width: 75%;
    height: 900px;
    display: table-cell;
    padding: 0;
    background: lighten($brown-link, 50%);
    .logo {
      margin: 15px auto;
      display: table;
    }
    .purple-content {
      background: $purple-bg;
      width: 100%;
      margin-bottom: 30px;
      color: $white-link;
      .name-small {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-size-default+12;
        font-family: $font-family-header;
      }
      .name-medium {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-size-franklin-h1;
        font-family: $font-family-header;
        small {
          font-size: $font-size-default+4;
        }
        span {
          margin-right: 20px;
          float: right;
        }
        .name-description {
          font-weight: normal;
          font-size: $font-size-default;
          font-family: $font-family-default;
          text-transform: none;
        }
      }

      .name-big {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-size-franklin-h1+10;
        font-family: $font-family-header;
        span {
          float: right;
          margin-right: 50px;
        }
        .name-description {
          font-weight: normal;
          font-size: $font-size-default;
          font-family: $font-family-default;
          text-transform: none;
        }
      }
    }
    .brown-content {
      background: $brown-link;
      width: 100%;
      color: $white-link;
      .name-small {
        color: $white-link;
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-size-default+12;
        font-family: $font-family-header;
        span {
          float: right;
        }
        span.bigger-font {
          font-size: $font-size-franklin-h1+10;
        }
        small {
          font-size: $font-size-default;
        }
        .name-description {
          font-weight: normal;
          font-size: $font-size-default;
          font-family: $font-family-default;
          text-transform: none;
        }
      }

      .name-medium {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-size-franklin-h2;
        font-family: $font-family-header;
        small {
          font-size: $font-size-default+4;
        }
        span {
          margin-right: 20px;
          float: right;
        }
        span.bigger-font {
          font-size: $font-size-franklin-h1+10;
          padding: 10px 0;
        }
      }
      .name-big {
        padding: 10px 30px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: $font-size-franklin-h1+10;
        font-family: $font-family-header;
        span {
          float: right;
          margin-right: 50px;
        }
        .name-description {
          font-weight: normal;
          font-size: $font-size-default;
          font-family: $font-family-default;
          text-transform: none;
        }
      }
    }
    .classic-content {
      width: 100%;
      padding-top: 20px;
      .purple-content {
        .name-medium {
          color: $white-link;
        }
        .name-small {
          color: $white-link;
          padding: 10px 30px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: $font-size-default+12;
          font-family: $font-family-header;
          .name-description {
            font-weight: normal;
            font-size: $font-size-default;
            font-family: $font-family-default;
            text-transform: none;
          }
        }
      }
      .brown-content {
        .name-small {
          color: $white-link;
          padding: 10px 30px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: $font-size-default+12;
          font-family: $font-family-header;
          span {
            float: right;
          }
        }

      }
      .name-small-list {
        padding: 0 30px;
        strong {
          font-size: $font-size-default+4;
        }
        .text-uppercase {
          text-transform: uppercase;
        }
      }
      .name-medium {
        padding: 0 30px;
        color: $dark-brown;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $font-family-header;
        font-size: $font-size-franklin-h1;
      }
      .half-width {
        width: 50%;
        display: table-cell;
        ul {
          list-style: none;
        }
        li strong {
          font-size: $font-size-default+2;
          font-family: $font-family-header;
        }
      }
      .list-with-description {
        ul {
          list-style-type: none;
          padding: 30px 30px 0 30px;
        }
        ul.small-padding {
          list-style-type: none;
          padding: 0 30px 0 30px;
          li {
            margin-bottom: 10px;
          }
        }
        li {
          margin-bottom: 20px;
        }
        li strong {
          font-size: $font-size-default+2;
          font-family: $font-family-header;
        }
        li span {
          float: right;
          font-weight: bold;
        }
      }
      .list-with-description-long {
        ul {
          list-style-type: none;
          padding: 0 30px;
        }
        li {
          margin-bottom: 10px;
        }
        li strong {
          font-size: $font-size-default+2;
          font-family: $font-family-header;
        }
        ul ul {
          padding: 0 15px;
        }

        ul ul li {
          margin-bottom: 0;
        }
      }
      .list-centered-bold {
        text-align: center;
        ul {
          list-style-type: none;
          padding: 0 30px;
        }
        li {
          font-weight: bold;
          font-size: $font-size-default+4;
          font-family: $font-family-header;
        }
        p {
          margin-top: 15px;
        }
      }
      .list-big-with-description {
        ul {
          list-style-type: none;
        }
        li {
          font-weight: bold;
          font-family: $font-family-header;
          font-size: $font-size-franklin-h3;
          margin-bottom: 15px;
          span {
            float: right;
            margin-right: 30px;
          }
          p {
            font-size: $font-size-default;
          }
        }
      }
      .three-column-list {
        ul {
          list-style-type: none;
          width: 30%;
          display: table-cell;
        }
      }
      table {
        margin: 15px 30px;
        thead {
          font-weight: bold;
        }
        thead td:nth-child(2) {
          padding-right: 8px;
        }
        tbody td:nth-child(2), tbody td:nth-child(3) {
          text-align: center;
        }
      }
    }
  }
}

/* Responsive */
/* MOBILNI */
@media screen and(max-width: 420px) {
  .franchise-list {
    .col-xs-6 {
      width: 100%;
    }
  }
}

@media screen and (max-width: 980px) {
  section .header-image {
    padding: 20px;
  }

  section .gray-page {
    padding: 20px;
  }

  section .slider-bg {
    padding: 20px;
  }

  section .page {
    padding: 20px;
  }
  section .location-slider-bg {
    padding: 20px 20px 10px 20px;
  }
  .header-image-with-text {
    height: 30%;
    width: 100%;
    h1 {
      font-size: $font-size-franklin-h2;

    }
  }
  section .page-without-top {
    padding: 0 20px 20px 20px;
    h2 {
      padding-top: 0;
      margin-top: 0;
    }
  }
  .location-list {
    .three-columns-list {
      width: 100%;
    }
  }
  .location-title {
    h1 {
      font-size: $font-size-default+8;
    }
  }
  /* RESPONSIVE OUR MENU */
  .our-menu-special {
    height: auto;
    width: 100%;
    display: block;
    table-layout: fixed;
    /* left */
    .day-name {
      height: 100%;
      display: block;
      width: 100%;
      position: relative;
      background: $dark-brown;
      h2 {
        margin: 0;
        padding: 15px 0;
        letter-spacing: 1px;
        text-align: center;
        color: $white-link;
        font-family: $font-family-header;
        font-size: $font-size-franklin-h1;
        text-transform: uppercase;
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    }
    /* right */
    .day-content {
      width: 100%;
      height: 100%;
      display: block;
      padding: 0;
      background: lighten($brown-link, 50%);
      .logo {
        margin: 0 auto;
        padding: 15px;
        display: table;
      }
      .purple-content {
        background: $purple-bg;
        width: 100%;
        margin-bottom: 0;
        color: $white-link;
        .name-small {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: $font-size-default+4;
          font-family: $font-family-header;
        }
        .name-medium {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: $font-size-franklin-h1;
          font-family: $font-family-header;
          small {
            font-size: $font-size-default+2;
          }
          span {
            margin-right: 0;
            float: right;
          }
          .name-description {
            font-weight: normal;
            font-size: $font-size-default;
            font-family: $font-family-default;
            text-transform: none;
          }
        }

        .name-big {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: $font-size-franklin-h1+4;
          font-family: $font-family-header;
          span {
            float: right;
            margin-right: 0;
          }
          .name-description {
            font-weight: normal;
            font-size: $font-size-default;
            font-family: $font-family-default;
            text-transform: none;
          }
        }
        .name-big.name-smaller-mob {
          font-size: $font-size-franklin-h3;
        }
      }
      .brown-content {
        background: $brown-link;
        width: 100%;
        color: $white-link;
        .name-small {
          color: $white-link;
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: $font-size-default+4;
          font-family: $font-family-header;
          span {
            float: right;
          }
          span.bigger-font {
            font-size: $font-size-franklin-h1;
            padding: 5px;
          }
          small {
            font-size: $font-size-default;
          }
          .name-description {
            font-weight: normal;
            font-size: $font-size-default;
            font-family: $font-family-default;
            text-transform: none;
          }
        }

        .name-medium {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: $font-size-franklin-h3;
          font-family: $font-family-header;
          small {
            font-size: $font-size-default+2;
          }
          span {
            margin-right: 0;
            float: right;
          }
        }
        .name-big {
          padding: 10px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: $font-size-franklin-h1+4;
          font-family: $font-family-header;
          span {
            float: right;
            margin-right: 0;
          }
          .name-description {
            font-weight: normal;
            font-size: $font-size-default;
            font-family: $font-family-default;
            text-transform: none;
          }
        }
        .name-big.name-smaller-mob {
          font-size: $font-size-franklin-h3;
        }
      }
      .classic-content {
        width: 100%;
        padding-top: 10px;
        .purple-content {
          .name-medium {
            color: $white-link;
          }
          .name-small {
            color: $white-link;
            padding: 10px;
            font-weight: bold;
            text-transform: uppercase;
            font-size: $font-size-default+4;
            font-family: $font-family-header;
            .name-description {
              font-weight: normal;
              font-size: $font-size-default;
              font-family: $font-family-default;
              text-transform: none;
            }
          }
        }
        .brown-content {
          .name-small {
            color: $white-link;
            padding: 10px;
            font-weight: bold;
            text-transform: uppercase;
            font-size: $font-size-default+12;
            font-family: $font-family-header;
            span {
              float: right;
            }
          }

        }
        .name-medium {
          padding: 10px;
          color: $dark-brown;
          text-transform: uppercase;
          font-weight: bold;
          font-family: $font-family-header;
          font-size: $font-size-franklin-h3;
        }
        .half-width {
          width: 100%;
          display: table;
          ul {
            padding: 0;
            margin: 15px;
            list-style: none;
          }
          li strong {
            font-size: $font-size-default+2;
            font-family: $font-family-header;
          }
        }
        .list-with-description {
          ul {
            list-style-type: none;
            padding: 10px;
          }
          ul.small-padding {
            list-style-type: none;
            padding: 0 10px;
            li {
              margin-bottom: 10px;
            }
          }
          li {
            margin-bottom: 20px;
          }
          li strong {
            font-size: $font-size-default+2;
            font-family: $font-family-header;
          }
          li span {
            float: right;
            font-weight: bold;
          }
        }
        .list-with-description-long {
          ul {
            list-style-type: none;
            padding: 0 30px;
          }
          li {
            margin-bottom: 10px;
          }
          li strong {
            font-size: $font-size-default+2;
            font-family: $font-family-header;
          }
          ul ul {
            padding: 0 15px;
          }

          ul ul li {
            margin-bottom: 0;
          }
        }
        .list-centered-bold {
          text-align: center;
          ul {
            list-style-type: none;
            padding: 0 30px;
          }
          li {
            font-weight: bold;
            font-size: $font-size-default+4;
            font-family: $font-family-header;
          }
          p {
            margin-top: 15px;
          }
        }
        .list-big-with-description {
          ul {
            list-style-type: none;
            margin: 0;
            padding: 15px;
          }
          li {
            font-weight: bold;
            font-family: $font-family-header;
            font-size: $font-size-default+4;
            margin-bottom: 10px;
            span {
              float: right;
              margin-right: 0;
            }
            p {
              font-size: $font-size-default;
            }
          }
        }
        .three-column-list {
          ul {
            list-style-type: none;
            width: 100%;
            display: table;
            margin: 0 auto
          }
        }
        table {
          margin: 15px;
          thead {
            font-weight: bold;
          }
          thead td:nth-child(2) {
            padding-right: 8px;
          }
          tbody td:nth-child(2), tbody td:nth-child(3) {
            text-align: center;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 740px) {
  .home-headings {
    font-size: $font-size-default+2;
  }
  .purple-title-big {
    padding: 10px;
    h2 {
      font-size: $font-size-default+2;
    }
  }

  .our-menu-seven-days {
    li {
      width: 100%;
      border-right: 0;
      border-bottom: 1px dashed lighten($purple-bg, 10%);
    }
    li:last-child {
      border-bottom: 0;
    }
  }
  .gallery figure {
    width: 50%;
  }
  .promotions-seven {
    display: none;
    width: 100%;
    background: $purple-bg;
    color: $white-link;
    padding: 10px;
    margin-bottom: 10px;
    h2 {
      border-right: 1px solid transparent;

    }
  }
  .starters {
    margin-bottom: 15px;
    .left {
      width: 100%;
      .big-image-rb {
        margin-top: 0;
        img {
          border-right: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
      .big-image-rt {
        img {
          border-right: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
    }
    .right {
      width: 100%;
      .big-image-lt {
        margin-bottom: 10px;
        img {
          border-left: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
    }
  }
  .salad-sandwiches {
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
      .big-image-bl {
        img {
          border-left: 0;
          border-top: 2px solid $brown-link;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
      .big-image-lb {
        img {
          border-left: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
    }
  }
  .signature-beverages {
    .left {
      width: 100%;
      .big-image-rt {
        margin-bottom: 15px;
        img {
          border-right: 0;
          border-bottom: 2px solid $brown-link;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
    }
    .right {
      width: 100%;
      .big-image-lb {
        img {
          border-left: 0;
          border-top: 2px solid $brown-link;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
      .big-image-ltb {
        img {
          border-left: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
    }
  }
  .after-dinner {
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
      .normal-image {
        img {
          border-left: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
      .normal-image-lb {
        img {
          border-top: 2px solid $brown-link;
          border-left: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }

    }
  }
  .breakfast-benedicts {
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
      .big-image-ltb {
        img {
          border-left: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
    }
  }
  .wines {
    .menu-header {
      padding: 10px 15px;
      margin: 10px 15px;
      .wine-image {
        position: relative;
        top: 0;
        right: 0;
      }
    }
    .menu-content {
      .left-width-10 {
        display: none;
      }
      .right-width-90 {
        width: 100%;
        padding: 0 15px;
      }
    }
  }
  .hot-and-cold {
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
  .from-the-bar {
    .left {
      width: 100%;
      .half-width {
        width: 100%;
        max-width: 100%;
        display: inline-block;
      }
    }
    .right {
      width: 100%;
      .half-width {
        width: 100%;
        max-width: 100%;
        display: inline-block;
      }
    }
    .full-width {
      .one-four-width {
        width: 100%;
        max-width: 100%;
        .big-image {
          max-width: 50%;
          width: 50%;
          position: relative;
          float: right;
        }
      }
    }
  }
  .martinis-menu {
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
  .entrees {
    .left {
      width: 100%;
      .big-image-trbr {
        img {
          border-right: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }
    }
    .right {
      width: 100%;
    }
  }
  .parties-to-go {
    .left.parties-to-go-right-bg {
      width: 100%;
    }
    .left {
      width: 100%;
      .footer-menu {
        padding: 0 15px;
        word-wrap: break-word;
        .social-links {
          a:first-of-type {
            font-size: $font-size-default;
          }
        }
      }
      .logo {
        img {
          padding: 0 15px;
        }
      }
    }
    .right {
      width: 100%;
      .text-centered.bold {
        padding: 0 15px;
      }
    }
  }
  .plan-your-party {
    .left.plan-your-party-right-bg {
      width: 100%;
    }
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
  .take-out-2 {
    .left.take-out-right-bg {
      width: 100%;
    }
    .left {
      width: 100%;
      .social-links {
        a:first-of-type {
          font-size: $font-size-default;
        }
      }
    }
    .right {
      width: 100%;
    }
  }
  .take-out-1 {
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
}

@media screen and (max-width: 520px) {
  .top-right-nav {
    li {
      border: 0 !important;
    }
  }
  .sub-navigation li {
    width: 100% !important;
  }
  .franchise-heading {
    margin-top: 15px;
  }

}

@media screen and (max-width: 980px) {
  .locations-list .three-columns-list {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .nav-top {
    .top-right-nav {
      max-width: 85%;
      li {
        float: right;
        padding: 0;
      }
      li:first-child {
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }
      li a {
        font-size: $font-size-default;
        padding: 10px;
      }
    }
  }
  .sub-navigation li {
    width: 50%;
  }
  .sub-navigation li:last-child {
    border-right: 1px dashed lighten($purple-bg, 10%);
  }
  .top-nav-left {
    margin: 5px 0 !important;
  }
  .signature-wrapper figure h2 {
    font-size: $font-size-default !important;
    font-weight: normal !important;
    padding: 10px 0 !important;
  }
  .slick-list:before {
    background: transparent !important;
  }

  .slick-list:after {
    background: transparent !important;
  }
  .get-social {
    bottom: 7px;
    text-align: center;
  }
  .get-social-list {
    text-align: center;
    display: inline-block;
    padding: 0 15px;
  }

  .get-social-list li {
    display: inline;
    margin-right: 7px;
    margin-bottom: 7px;
    background: transparent;

  }
  header .logo {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .col-lg-4.yellow-bg {
    display: inline-block;
    margin: 0 15px;
  }
  .row-eq-height {
    display: block;
  }
  .location-banner {
    height: auto;
  }
  .location-for-you-list {
    width: 100%;
  }
  .location-widget {
    display: inline-block;
    width: 100%;
    margin-top: 0;
  }
  .widget address {
    text-align: center;
  }
  .widget span:first-of-type {
    text-align: center;
    display: table;
    margin: 0 auto;
  }
  .widget .location-map {
    margin-bottom: 10px;
    height: 250px;
    width: 100%;
  }
  .location-socials {
    .locations-reviews-list {
      float: none;
      width: 100%;
    }

    .social-widgets {
      li {
        padding: 0;
        display: block;
        max-width: 100%;
        margin: 0 auto;
        list-style-type: none;

      }
      iframe {
        max-width: 220px !important;
      }
      #CDSSCROLLINGRAVE.narrow {
        max-width: 220px !important;
      }
    }
  }
  .current-opportunities {
    .header {
      padding: 15px;
      h2 {
        text-align: center;
        float: none;
        max-width: 100%;
        font-size: $font-size-franklin-h1;
        line-height: $font-size-franklin-h1;
      }
      .logo {
        float: none;
        max-width: 100%;
        padding: 10px 30px;
      }
    }
    .content {
      padding: 15px;
      ul {
        font-size: $font-size-default+4;
        width: 100%;
        max-width: 100%;
        float: none;
        padding: 0 15px;
      }
    }

    .full-width {
      position: relative;
      .border-box {
        padding: 15px;
        width: 100%;
        float: none;
        position: relative;
        right: 0;
        bottom: 0;
        background: $yellow-bg;
        border: 3px solid $dark-brown;
        -webkit-border-top-left-radius: 0;
        -webkit-border-top-right-radius: 0;
        -moz-border-radius-topleft: 0;
        -moz-border-radius-topright: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        ul {
          font-size: $font-size-default+6;
          font-weight: bold;
          padding: 0;
          font-family: $font-family-header;
          li {
            width: 100%;
            display: block;
          }
          li:before {
            content: '•';
            margin-right: 10px;
          }
        }
      }
    }
    .awards-texts {
      display: table;
      position: relative;
      ul {
        max-width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          float: left;
          display: inline;
          width: 50%;
          padding: 5px;
          img {
            width: 100%;
          }
        }
      }
    }
    .informations {
      margin-top: 15px;
      padding: 15px;
      display: block;
      width: 100%;
      text-align: center;
      -ms-word-wrap: break-word;
      word-wrap: break-word;
      font-size: $font-size-default+4;
    }
  }
}

/* Navigation */
.responsive-nav {
  display: none;
}

.top-left-list {
  @extend %list-style-inline-ul;

  li {
    @extend %list-style-inline-li-nav;
  }
  li a {
    border-right: 1px dashed lighten($black, 10%);
    width: 100%;
    display: block;
    padding: 9px 15px;
    &:hover {
      background: lighten($black, 10%);
    }
  }
  li:first-child {
    border-left: 1px dashed lighten($black, 10%);
  }
}

@media screen and (max-width: 1200px) {
  .centered {
    display: block;
  }

  /* .top-nav-left {
     position: relative;
     z-index: 1;
     width: 48px;
     height: 48px;
     color: $white-link;
     background-color: lighten($black, 20%) !important;
     overflow: hidden;
     border-radius: 3px;
     cursor: pointer;
     float: left;
     margin: 10px 0;
     max-width: 15%;
     li a, li:first-child {
       border: 0;
       width: 100%;
     }
     @media screen and (min-width: 1200px) {
       position: static;
       width: auto;
       height: auto;
       background: none;
       color: $white-link;
     }

     &:after {
       content: "☰";
       font-size: $font-size-default+2;
       width: 1em;
       line-height: 1;
       text-align: center;
       position: absolute;
       top: 50%;
       left: 50%;
       margin-top: - (16px / 2);
       margin-left: - (16px / 2);

       @media screen and (min-width: 1200px) {
         content: normal;
         content: none;
       }

     }

     &.is-open {
       overflow: visible;
       &:after {
         content: "✕";
         @media screen and (min-width: 1200px) {
           content: normal;
           content: none;
         }
       }
     }
   }*/
  /*.top-left-list {
    list-style: none;
    margin: 0;
    padding: 48px 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    background-color: lighten($black, 10%);
    display: inline-block;
    border-radius: 3px;
    z-index: -1;
    li {
      background: $white-link;
      width: 100%;
    }
    li a {
      width: 100%;
      display: block;
      padding: 5px 15px;
    }
    li a:hover {
      background: lighten($black, 10%);
    }
  }*/
}

/*
@media screen and (min-width: 1200px) {
  .top-left-list {
    padding-top: 0;
    width: auto;
    display: block;
    background: none;
    text-align: left;
  }
*/

/* Master navigatioin */

a.open {
  display: none;
  float: left;
  margin-top: 15px;
  color: $white-link;
  font-family: $font-family-header;
  text-transform: uppercase;
  font-size: $font-family-default+6;
  padding: 15px 20px;
  text-align: center;
  background: $purple-bg;
  border: 0;
}

#pageslide {
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  background-color: $black;
  color: $white-link;
  overflow-y: scroll;
  border-right: 3px solid $brown-link;
}

.hidden-pc {
  display: none;
}

@media only screen and (max-width: 980px) {
  .top-nav-left, .top-right-nav {
    display: none;
    border: 0;
    margin: 0;
    padding: 0;
    float: none;
  }
  .width100 {
    width: 100%;
    display: inline-block;
  }
  .responsive-nav {
    display: block;
    background: $black;
    padding-bottom: 100px;
    .logo {
      img {
        margin: 0 auto;
        display: table;
      }
    }
  }
  #nav {
    display: none;
  }
  a.open {
    margin-left: 30px;
    display: block;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
  #pageslide {
    width: 220px;
    margin: 0;
    .logo {
      display: none;
    }
    .padding-logo-nav {
      padding: 0;
    }
    nav {
      margin: 0;
    }
    .hidden-pc {
      display: block;
    }
  }
  #pageslide nav ul {
    padding: 0 15px;
  }
  #pageslide nav li {
    padding: 10px 0;
    width: 100%;
  }
  #pageslide nav ul ul {
    display: none;
  }
  #pageslide nav ul li:hover ul {
    display: none;
  }
  #pageslide #nav nav li a {
    width: 100%;

    color: $white-link;
  }
  #pageslide #nav nav li a:hover {
    color: $brown-link;
  }
}

.list-padding0 {
  padding-left: 0 !important;
}

.content-in-middle-pd {
  padding: 0 30px 15px 30px;
}

@mixin clearfix() {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@import "our-menu/take-out";
@import "our-menu/private-party";
@import "our-menu/parties-to-go";
@import "our-menu/kids";
@import "our-menu/entrees";
@import "our-menu/wine";
@import "our-menu/desserts";
@import "our-menu/starters";
@import "our-menu/burgers";
@import "our-menu/martinis";
@import "our-menu/beverages";
@import "our-menu/earlybird";

@import "pages/mothersday";

.bg-custom-take-out {
  background: url('/images/our-menu/take-out-left-side.jpg') no-repeat left center;
  min-height: 1200px;
  position: relative;
  @media screen and (max-width: 720px) {
    max-height: 100%;
  }
}

.bottom-containers {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  .bottom-white-row {
    padding: 15px;
    color: #333;
    small {
      color: #333;
    }
    background: rgba(255, 255, 255, 0.7);
  }
  .bottom-purple-row {
    padding: 15px;
    background: lighten($purple-bg, 10%);
  }
}

.left-side-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
  padding: 30px 15px;
  background: rgba(0, 0, 0, 0.5);
  .social-links {
    a:first-of-type {
      margin-bottom: 15px;
      display: block;
    }
    ul {
      margin: 0 auto;
    }
  }
}

.our_menu-early {
  position: relative;
  max-width: 550px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  @include clearfix();
  &.bg-gray, .bg-gray {
    background-color: #e4e5e9;
  }
  &.bg-black {
    background: black !important;
    color: white;
  }

  ul {
    padding: 0;
    list-style: none;
    li {
      position: relative;
      text-align: left;
    }
  }
  h1, h2.imitate-h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 42px;
  }

  p,
  span {
    font-size: 12px;
  }

  .cals,
  .price {
    float: right;
  }

  .price {
    margin-right: 5px;
  }

  li strong {
    font-size: 14px;
  }
  .our-menu-title-centered {
    text-align: center;
    padding: 15px !important;
    .img-responsive {
      width: auto;
      display: table;
      margin: 0 auto;
    }
  }
  .full-container {
    width: 100%;
    display: table;
    padding: 0 30px;
    &.bg-black {
      background: black !important;
      color: white;
    }
  }
}

.gallery.contest figure {
  width: 100%;
  position: absolute;
  left: 0;
  margin: auto;
  top: 0;
  right: 0;
}

@media screen and(max-width: 780px) {
  .gallery.contest figure {
    width: 100%;
  }
}

.contest-list li {
  margin-bottom: 10px;
}