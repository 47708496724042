.our_menu-entrees {
  position: relative;
  display: block;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  &.bg-gray {
    background-color: #e4e5e9;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  p,
  span {
    font-size: 12px;
  }

  .cals,
  .price {
    float: right;
  }

  .price {
    margin-right: 5px;
  }

  li {
    position: relative;
  }
  li strong {
    font-size: 14px;
  }

  .header_background {
    background: black;
    width: 100%;
    height: 90px;
    padding: 5px;
  }

  .header_background h1 strong {
    color: #ffe9ca;
    font-size: 50px;
    padding: 0 45px;
  }

  .left-side,
  .right-side {
    float: left;
    width: 50%;
    padding: 30px;
    display: block;
  }

  .left-side:after {
    content: '';
    display: block;
    clear: both;
  }

  .right-side:after {
    content: '';
    display: block;
    clear: both;
  }

  .middle_content {
    width: 100%;
    text-align: center;
    display: inline-block;
    padding: 0 30px;
  }

  .middle_content h2:after,
  .middle_content h2:before {
    content: '';
    width: 30%;
    border-bottom: solid 1px black;
    position: absolute;
    margin-top: 15px;
  }

  .middle_content h2:after {
    left: 0;
  }

  .middle_content h2:before {
    right: 0;
  }

  .middle_content h2 {
    width: auto;
    position: relative;
  }

  .purple {
    color: #3C1C42;
    text-align: left;
  }

  .small_strong_text {
    font-size: 12px;
  }

  .image-top {
    max-width: 400px;
    margin-top: -24px;
    margin-right: -30px;
    float: right;
  }

  .image-shape {
    max-width: 225px;
    display: block;
    margin: 20px -50px 0 0;
    shape-outside: url(/images/our-menu/meal-left-side.svg);
    shape-image-threshold: 0.5;
    float: right;
  }

  .side_rectangle {
    background-color: black;
    padding: 10px;
    margin-top: -130px;
  }

  .side_rectangle strong {
    color: #ffd79c;

  }

  @media screen and (max-width: 720px) {
    .left-side,
    .right-side {
      width: 100% !important;
    }

    .middle_content h2:after,
    .middle_content h2:before {
      width: 20%;
    }

    .image-top {
      max-width: 300px;
      margin-bottom: 30px;
    }

    .side_rectangle {
      margin-top: -30px;
    }
  }
  .icon-symp-gluten {
    width: 20px;
    position: absolute;
    left: -25px;
    top: 0;
  }
  .abs-top-20 {
    top: 20px;
  }
  @media screen and (max-width: 470px) {
    .middle_content h2:after,
    .middle_content h2:before {
      width: 10%;
    }
    .image-shape {
      float: none;
      margin-bottom: 5px;
    }
  }

}

