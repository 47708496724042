
.our_menu-take-out {
  margin-bottom: 30px;
  &.bg-black {
    background: black !important;
    color: white;
    small {
      color: white;
    }
  }
  &.bg-gray {
    background: #e4e5e9 !important;
  }
  @include clearfix();
  .left-side, .right-side {
    float: left;
    width: 50%;
    padding: 30px;
    @include clearfix();
  }
  .sub-title {
    font-weight: bold;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    li {
      position: relative;
      display: block;
      text-align: right;
      @include clearfix();
      span {
        &:first-child {
          float: left;
        }
        display: inline-block;
      }
      img {
        width: 20px;
        position: absolute;
        left: -25px;
        top: 0;
        &.none-position {
          position: relative;
          top: auto;
          left: auto;
        }
      }
    }
  }

  .left-side-bg {

  }
  .social-links {
    img {
      width: auto !important;
      position: relative !important;
      top: auto !important;
      left: auto !important;
    }
  }
}

// responsive take out
@media screen and (max-width: 720px) {
  .our_menu-take-out {
    .left-side, .right-side {
      width: 100% !important;
    }
  }
}

// responsive take out