
.event-price-list {
  display: flex;
  margin: 0 auto;

  .left {
    width: 25%;
    position: relative;
    background: #341905;
  }

  .right {
    width: 75%;
  }

  .rotated h1 {
    letter-spacing: 5px;
    color: #f5f5f5;
    font-family: "FranklinGothicBookBold", sans-serif;
    font-size: 100px;
    text-transform: uppercase;
    transform: rotate(90deg);
    white-space: nowrap;
    margin-top: 150px;
  }

  .rotated {
    text-align: center;
  }

  .price {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin: 30px auto;
    color: white;
    font-size: 32px;
  }
  .menu_title {
    padding: 30px 30px 20px 30px;
    margin-top: -150px;
    h2 {
      font-size: 38px;
      font-weight: bold;
      color: #871778;

      text-shadow: -1px -1px 0 white,
      1px -1px 0 white,
      -1px 1px 0 white,
      1px 1px 0 white;
    }
    h3 {
      font-size: 28px;
      color: black;

      text-shadow: -1px -1px 0 white,
      1px -1px 0 white,
      -1px 1px 0 white,
      1px 1px 0 white;
    }
  }
  .choice-white {
    padding: 20px 30px;
    background: white;
    color: #871778;
    span {
      font-size: 24px;
      font-weight: bold;
      display: block;
    }
  }
  .choice-rose {
    background: #f3b3bb;
    padding: 20px 30px;
    span {
      font-size: 24px;
      font-weight: bold;
      display: block;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
  .choice-dessert {
    padding: 20px 30px;
    background: white;
    color: #871778;
    span {
      font-size: 24px;
      font-weight: bold;
      display: block;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }
  @media screen and (max-width: 992px) {
    .menu_title {
      padding: 30px 20px 20px 20px;
      h2 {
        font-size: 34px;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    display: block;
    margin: 0 auto;
    .right, .left {
      width: 100%;
    }
    .rotated h1 {
      letter-spacing: inherit;
      font-size: 48px;
      transform: inherit;
      margin-top: 0;
    }
    .price {
      position: relative;
      text-align: center;
      margin: 15px auto;
      font-size: 24px;
    }
  }
  @media screen and (max-width: 400px) {
    .rotated h1 {
      font-size: 30px !important;
    }
    .menu_title {
      margin-top: -70px;
      padding: 15px;

      h2 {
        margin: 0;
      }
    }
    .choice-dessert, .choice-rose, .choice-white {
      padding: 15px;
    }
  }
}