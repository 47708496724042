
.our_menu-martinis {
  position: relative;
  display: block;
  overflow: hidden;
  @include clearfix();
  &.bg-gray, .bg-gray {
    background-color: #e4e5e9;
  }
  &.bg-black {
    background: black !important;
    color: white;
  }
  ul {
    padding: 0;
    list-style: none;
    li {
      position: relative;
      text-align: left;
    }
  }
  h1, h2.imitate-h1 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 42px;
  }

  p,
  span {
    font-size: 12px;
  }

  .cals,
  .price {
    float: right;
  }

  .price {
    margin-right: 5px;
  }

  li strong {
    font-size: 14px;
  }
  .our-menu-title-centered {
    text-align: center;
    padding: 15px !important;
    .img-responsive {
      width: auto;
      display: table;
      margin: 0 auto;
    }
  }
  .full-container {
    width: 100%;
    display: table;
    padding: 0 30px;
    &.bg-black {
      background: black !important;
      color: white;
    }

  }

  .left-side-70, .right-side-70 {
    position: relative;
    float: left;
    width: 70%;
    padding: 30px;
    @include clearfix();
  }
  .left-side-30, .right-side-30 {
    position: relative;
    float: left;
    width: 30%;
    padding: 30px;
    @include clearfix();
  }

  .martinis-right-image {
    max-width: 100%;
  }
  .martinis-left-image {
    position: absolute;
    left: -50px;
    width: 120%;
  }
  @media screen and (max-width: 768px) {
    .full-container {
      padding: 0 15px;
    }
    .left-side-70, .right-side-70, .left-side-30, .right-side-30 {

      width: 100%;
      padding: 15px;
    }
    .martinis-left-image {
      position: absolute;
      left: auto;
      right: -15px;
      width: 20%;
    }
    .martinis-right-image {
      display: table;
      margin: 0 auto;
      max-width: 60%;
    }
  }
  @media screen and (max-width: 670px) {
    .martinis-left-image {
      display: none;
    }
  }

}