
.our_menu-starters {
  margin-bottom: 30px;
  padding-top: 20px;
  &.bg-black {
    background: black !important;
    color: white;
    small {
      color: white;
    }
  }
  &.bg-gray {
    background: #e4e5e9 !important;
  }

  @include clearfix();

  .full-container {
    width: 100%;
    display: table;
    &.bg-black {
      background: black !important;
      color: white;
    }
    img.img-responsive {
      max-width: 100%;
      position: absolute;
    }
    h3 {
      margin-top: 0;
      padding-top: 0;
    }
    h4 {
      margin-bottom: 0;
    }
  }
  .left-side, .right-side {
    position: relative;
    float: left;
    width: 50%;
    padding: 30px;
    @include clearfix();
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    li {
      position: relative;
      display: block;
      @include clearfix();
      span {
        float: right;
      }
      .icon-symp, .icon-symp-gluten {
        width: 20px;
        position: absolute;
        left: -25px;
        top: 0;
      }
    }
  }
  .starters-img-right {
    margin-top: -70px;
  }
  .left-container {
    margin: 20px -30px 0 -30px;
    width: 100%;
    padding-left: 20px;
    position: absolute;
  }
  .full-starters-container {
    position: relative;
    img.img-responsive {
      margin-top: -100px;
      width: 40%;
    }
    h2 {
      margin-left: 42%;
    }
  }

  .bordered-box-radius {
    padding: 10px;
    border: 2px solid black;
    display: block;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    p {
      margin: 0;
      font-size: 12px;
    }
  }
}

