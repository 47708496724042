.parties-to-go {
  height: 900px;
  width: 100%;
  display: table;
  table-layout: fixed;
  position: relative;
  background: #e4e5e9;
  margin-bottom: 15px
}

.parties-to-go .menu-container {
  float: left;
  width: 100%;
  position: relative;
  right: 50%
}

.parties-to-go .left {
  float: left;
  width: 50%;
  position: relative;
  left: 50%;
  overflow: hidden;
  vertical-align: top;
  height: 100%;
  color: #111;
  background: #e4e5e9
}

.parties-to-go .left .purple-colored {
  color: #7d3a8a;
  float: none;
  font-weight: 700
}

.parties-to-go .left .logo {
  margin: 30px auto;
  display: table;
  max-width: 100%
}

.parties-to-go .left .footer-menu {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 0, 0, .5);
  color: #f5f5f5;
  text-align: center
}

.parties-to-go .left .footer-menu h2:first-of-type {
  font-size: 38px
}

.parties-to-go .left .footer-menu h2:first-of-type, .parties-to-go .left .footer-menu h3 {
  text-align: center;
  font-family: FranklinGothicBookBold, sans-serif;
  color: #f5f5f5;
  font-weight: 700
}

.parties-to-go .left .footer-menu h3 {
  font-size: 28px;
  padding: 5px 10px
}

.parties-to-go .left .footer-menu a.big-link {
  text-align: center;
  margin: 10px auto;
  display: table;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #f5f5f5
}

.parties-to-go .left h2 {
  font-family: FranklinGothicBookBold, sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  padding: 5px 20px
}

.parties-to-go .left h2 small {
  font-size: 14px;
  float: right;
  vertical-align: text-bottom;
  color: #111
}

.parties-to-go .left h3 {
  padding: 0 20px;
  font-family: FranklinGothicBookBold, sans-serif;
  font-weight: 700;
  font-size: "Helvetica Neue", Helvetica, Arial, sans-serif4
}

.parties-to-go .left ul {
  padding: 0 20px;
  list-style-type: none
}

.parties-to-go .left ul li span {
  float: right
}

.parties-to-go .left .text-centered {
  text-align: center
}

.parties-to-go .left .line-dashed {
  height: 1px;
  margin: 20px 0;
  border-top: 1px dashed #fff
}

.parties-to-go .left.parties-to-go-right-bg {
  min-height: 1100px;
  background: #111 url(../../images/our-menu/parties-to-go.jpg) no-repeat 50%
}

.parties-to-go .left.parties-to-go-right-bg, .parties-to-go .right {
  float: left;
  width: 50%;
  position: relative;
  left: 50%;
  overflow: hidden;
  vertical-align: top;
  height: 100%;
  color: #111
}

.parties-to-go .right {
  background: #e4e5e9
}

.parties-to-go .right .logo {
  margin: 30px auto;
  display: table
}

.parties-to-go .right .footer-menu {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  background: rgba(0, 0, 0, .5);
  color: #f5f5f5;
  text-align: center
}

.parties-to-go .right .footer-menu h1 {
  font-size: 38px
}

.parties-to-go .right .footer-menu h1, .parties-to-go .right .footer-menu h3 {
  text-align: center;
  font-family: FranklinGothicBookBold, sans-serif;
  color: #f5f5f5;
  font-weight: 700
}

.parties-to-go .right .footer-menu h3 {
  font-size: 28px;
  padding: 5px 10px
}

.parties-to-go .right .footer-menu a.big-link {
  text-align: center;
  margin: 10px auto;
  display: table;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #f5f5f5
}

.parties-to-go .right h2 {
  font-family: FranklinGothicBookBold, sans-serif;
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;
  padding: 5px 20px
}

.parties-to-go .right h2 small {
  font-size: 14px;
  float: right;
  vertical-align: text-bottom;
  color: #111
}

.parties-to-go .right h3 {
  padding: 0 20px;
  font-family: FranklinGothicBookBold, sans-serif;
  font-weight: 700;
  font-size: "Helvetica Neue", Helvetica, Arial, sans-serif4
}

.parties-to-go .right ul {
  padding: 0 20px;
  list-style-type: none
}

.parties-to-go .right ul li span {
  float: right
}

.parties-to-go .right .text-centered {
  text-align: center
}

.parties-to-go .right .line-dashed {
  height: 1px;
  margin: 20px 0;
  border-top: 1px dashed #fff
}

.parties-to-go .right.parties-to-go-right-bg {
  width: 100%;
  height: 100%;
  display: inline;
  background: #111 url(../../images/our-menu/parties-to-go.jpg) no-repeat 50%
}